import React from "react"
import Duolingo from "./duolingo"
import Facebook from "./facebook"
import { Profile } from "design_system/utils/types/profile"

export const types = {
  duolingo: "duolingo",
  facebook: "facebook",
}
export type typesType = keyof typeof types

export interface ProfileSocialLinkProps {
  profile: Profile
  type: typesType
  /**
   * Labels default to the link type but passing "username" will display that
   * username. We do not support custom labels at this time
   */
  label?: "username" | string | null

  /**
   * Labels can be displayed next to the item
   */
  showLabel?: boolean

  /**
   * Alternative to the label next to the icon is displaying a tooltip when
   * hovering. Both options can be used at the same time
   */
  showTooltip?: boolean
}

const ProfileSocialLink = ({
  type,
  showLabel = true,
  showTooltip = true,
  ...args
}: ProfileSocialLinkProps): React.ReactNode | null => {
  switch (type) {
    case types.duolingo:
      return (
        <Duolingo {...args} showLabel={showLabel} showTooltip={showTooltip} />
      )
    case types.facebook:
      return (
        <Facebook {...args} showLabel={showLabel} showTooltip={showTooltip} />
      )
    default:
      return null
  }
}

export default ProfileSocialLink

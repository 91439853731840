import sprite from "./sprite.png"

import icon32 from "./icon_32.png"
import elements from "design_system/adventure/codex/elements"

const Spell = {
  type: "spell",
  name: "Fire hammer",
  irishName: "Molta tine",
  element: elements.fire.id,
  spellText: {
    english: "Smash, Fire hammer!",
    irish: "Smaoinigh, molta tine!",
  },
  sprite,
  spriteSettings: {
    frameCount: 19,
    height: 144,
    width: 128,
  },
  icons: {
    32: icon32,
  },
}

export default Spell

import React, { Children } from "react"
import PropTypes from "prop-types"
import joinChildren from "design_system/utils/render_children_seperator"

import BreadcrumbDivider from "./divider"
import BreadcrumbItem from "./item"
import classnames from "classnames"
import { cn } from "design_system/libs"

export const sizes = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-base",
  lg: "text-lg",
  xl: "text-xl",
  "2xl": "text-2xl",
}

const Breadcrumb = ({ children, className = "", size }) => {
  const items = Children.map(children, (child) =>
    child.type.displayName === "BreadcrumbItem" ? child : null
  )

  const fullClassName = cn("flex text-gray-400", className, {
    [sizes[size]]: size,
  })
  return (
    <nav className={fullClassName} aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-3">
        {joinChildren(items, BreadcrumbDivider, { size })}
      </ol>
    </nav>
  )
}

Breadcrumb.Item = BreadcrumbItem
Breadcrumb.Divider = BreadcrumbDivider

Breadcrumb.propTypes = {
  /**
   * The content of the component.
   */
  size: PropTypes.oneOf(Object.keys(sizes)),
}

export default Breadcrumb

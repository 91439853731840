import sprite from "./sprite.png"
import icon32 from "./icon_32.png"
import elements from "design_system/adventure/codex/elements"

const Spell = {
  type: "spell",
  name: "Water blast",
  irishName: "Blast uisce",
  element: elements.water.id,
  spellText: {
    english: "Go, water blast!",
    irish: "Imigh, blast uisce!",
  },
  sprite,
  spriteSettings: {
    frameCount: 13,
    width: 128 / 2,
    height: 224 / 2,
  },
  icons: {
    32: icon32,
  },
}

export default Spell

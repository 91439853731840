import React from "react"
import Hero from "./hero"
import SpellCastingSection from "./spell_casting_section"
import LootDropSection from "./loot_drop_section"
import ExploreDungeonSection from "./explore_dungeon_section"
import StoriesSection from "./stories_section"
import MiniGamesSection from "./mini_games_section"
import CreaturesSection from "./creatures_section"
import CodexSection from "./codex_section"

const Separator = () => (
  <div className="container py-12">
    <hr />
  </div>
)

const AdventureFeaturesIndexTemplate = () => (
  <div>
    <Hero />
    {/*<div>About section</div>*/}
    <ExploreDungeonSection />
    <Separator />
    <LootDropSection />
    <Separator />
    <CodexSection />
    <Separator />
    <MiniGamesSection />
    <Separator />
    <SpellCastingSection />
    {/*<Separator />*/}
    {/*<CreaturesSection />*/}
    {/*<div>Join community</div>*/}
  </div>
)

export default AdventureFeaturesIndexTemplate

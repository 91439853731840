import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

export interface OptionProps extends React.HTMLAttributes<HTMLLIElement> {
  id: string
}

function Option({ id, children }: OptionProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString({ ...(transform || {}), scaleX: 1 }),
    transition,
  }

  return (
    <li
      ref={setNodeRef}
      style={transform ? style : {}}
      {...attributes}
      {...listeners}
      className="card inline-block"
    >
      {children}
    </li>
  )
}

export default Option

import ReactOnRails from 'react-on-rails';

import "stylesheets/application.css";

// Import components here so their styles are available in the bundle
import "../config/static_styles";

import "../static_assets";
import ReactApp from '../app'
import CookieConsent from "../rails_components/cookie_consent";
import AppHeader from "../rails_components/app_header";
import AppFooter from "../rails_components/application/app_footer";
import Breadcrumb from "../rails_components/application/breadcrumb";
import Icon from "../rails_components/icons";
import SpriteAnimator from "../rails_components/sprite_renderer";

import UserDashboardIndexPage from "../pages/user/dashboard";
import pages from "../pages"


ReactOnRails.register({
  ...pages,
  ReactApp,
  CookieConsent,
  AppHeader,
  AppFooter,
  Breadcrumb,
  Icon,
  SpriteAnimator,
  UserDashboardIndexPage,
});

import { visit } from "unist-util-visit"

export default function RemarkDropcap(options) {
  const dropcapClass =
    options && "dropcapClass" in options ? options.dropcapClass : "dropcap"
  const invisibleClass =
    options && "invisibleClass" in options ? options.invisibleClass : "sr-only"

  let count = 0

  const visitor = function (node, index) {
    // only work on first valid markdown paragraph encountered
    if (
      count > 0 ||
      index > 1 ||
      !("children" in node) ||
      node.children.length <= 0 ||
      !("value" in node.children[0])
    ) {
      return
    }
    count++
    const para = node.children[0].value
    const word = para.split(" ")[0]
    const letter = word.charAt(0)
    const part = word.substr(1)
    const text = para.substr(word.length)

    // remove first word from text
    node.children[0].value = text

    // replace what was first word with accessible dropcapped markup
    node.children.unshift(
      {
        type: "emphasis",
        children: [
          {
            type: "emphasis",
            children: [
              {
                type: "text",
                value: letter,
              },
            ],
            data: {
              hName: "span",
              hProperties: { className: dropcapClass },
            },
          },
          {
            type: "text",
            value: part,
          },
        ],
        data: {
          hName: "span",
          hProperties: { ariaHidden: "true", className: "select-none" },
        },
      },
      {
        type: "emphasis",
        children: [
          {
            type: "text",
            value: word,
          },
        ],
        data: {
          hName: "span",
          hProperties: { className: invisibleClass },
        },
      }
    )
  }

  var transformer = function (node, file) {
    visit(node, "paragraph", visitor)
  }

  return transformer
}

import React from "react"
import { useQuery } from "@apollo/client"

import ChangelogListItem from "design_system/application/components/changelog_list_item"
import Title from "design_system/core/components/title"
import robotEmoji from "images/pixel_art/emojis/115_robot.png"
import query from "./query.graphql"
import SkeletonLoader from "design_system/core/components/skeleton_loader/content"
import { test } from "@love-irish/hello"
const ChangelogListTemplate = ({ items = [], loading = false }) => {
  test()
  return (
    <div className="container grid gap-10 grid-cols-12 mt-6">
      <div className="col-span-12">
        <header className="flex justify-between flex-col">
          <div>
            <div className="mt-16">
              <Title title="Changelog" dropcap="sm" size="lg" />
            </div>
          </div>
        </header>
      </div>
      <div className="col-span-8 grid gap-8 divide-y">
        {loading && (
          <div className="mt-8">
            <SkeletonLoader />
          </div>
        )}
        {!loading && items.length === 0 && (
          <Title
            title="No changelog items to show. Check back later"
            size="sm"
            className="mt-8"
          />
        )}

        {items.map((item) => (
          <div key={item.id} className="pt-10">
            <ChangelogListItem {...item} />
          </div>
        ))}
      </div>

      <aside className="col-span-4 mt-8">
        <div className="flex items-center gap-3 text-md">
          <img
            src={robotEmoji}
            alt="Robot emoji – 🤖"
            width={24}
            className="pixel-art"
          />
          <p className="font-mono align-middle">
            Beep boop... These are our major updates
          </p>
        </div>

        <p className="mt-4">
          Be sure to check back here for all the latest updates to the site
          including new features, bug fixes, balance changes, new items and
          spells, and more!
        </p>

        <p className="mt-4">
          Updates follow the semantic versioning convention of{" "}
          <code className="font-mono">MAJOR.MINOR.PATCH</code>
        </p>
      </aside>
    </div>
  )
}

export const ConnectedComponent = (props) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: "changelog" },
  })
  return (
    <ChangelogListTemplate
      loading={loading}
      items={loading ? [] : data.collection?.items?.nodes || []}
    />
  )
}

export default ChangelogListTemplate

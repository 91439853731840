import { create } from "zustand"
import { createStore } from "zustand/vanilla"
const store = (set, get) => ({
  progress: 0,
  total: 0,

  initialize: (newState) => set((state) => ({ ...newState, progress: 0 })),
  incrementProgress: () => set((state) => ({ progress: state.progress + 1 })),
  onComplete: () => set((state) => ({ progress: state.total })),

  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
})

export const useStoryTellerStore = create(store)
export const jsStoryTellerStore = createStore(store)

import React from "react"
import Tooltip from "design_system/core/components/tooltip"

const GreetingHeader = ({ currentUser = {}, date = new Date() }) => {
  const currentHour = date.getHours()
  console.log({ currentHour })

  const isMorning = currentHour < 12
  const isDay = currentHour >= 12 && currentHour < 18
  const isNight = currentHour >= 18
  let currentGreeting

  const greetings = {
    morning: {
      irish: "Maidin mhaith",
      emoji: "☕️",
      english: "Good morning",
    },
    day: {
      irish: "Tráthnóna maith",
      emoji: "☀️",
      english: "Good afternoon",
    },
    night: {
      irish: "Oíche mhaith",
      emoji: "🌙",
      english: "Good night",
    },
    halloween: {
      irish: "Oíche Shamhna shona duit",
      emoji: "🎃",
      english: "Happy halloween",
    },
  }

  if (isMorning) {
    currentGreeting = greetings.morning
  } else if (isDay) {
    currentGreeting = greetings.day
  } else {
    currentGreeting = greetings.night
  }

  if (date.getMonth() === 9 && date.getDate() === 31) {
    currentGreeting = greetings.halloween
  }

  return (
    <h1 className="text-3xl font-fancy">
      <Tooltip
        title={`${currentGreeting.english}${
          currentUser.slug ? `, @${currentUser.slug}` : ", my friend"
        }`}
      >
        <div>
          {currentGreeting.emoji}{" "}
          <span className="text-definition">
            {currentGreeting.irish},{" "}
            {currentUser.slug ? `@${currentUser.slug}` : "a chara"}
          </span>
        </div>
      </Tooltip>
    </h1>
  )
}

export default GreetingHeader

import React from "react"
import InterviewDetailsTemplate from "design_system/application/templates/interview_details"
import normalize from "json-api-normalize"
export const ChangelogIndexPage = ({ item }) => {
  const interview = normalize(item).get([
    "id",
    "name",
    "slug",
    "summary",
    "content",
    "image",
    "publishedAt",
    "moderatedAt",
    "updatedAt",
    "questions.id",
    "questions.name",
    "questions.content",
    "questions.source",
    "questions.answers",
    "questions.answers.id",
    "questions.answers.name",
    "questions.answers.content",
  ])

  return <InterviewDetailsTemplate item={interview} />
}

export default ChangelogIndexPage

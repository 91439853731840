"use client"

import React from "react"
import classNames from "classnames"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeExternalLinks from "rehype-external-links"
import PropTypes from "prop-types"
import { useIsMounted } from "usehooks-ts"
export const lineLengths = {
  "1": "line-clamp-1",
  "2": "line-clamp-2",
  "3": "line-clamp-3",
  "4": "line-clamp-4",
  "5": "line-clamp-5",
  full: "line-clamp-none",
}

export type lineLengthsType = keyof typeof lineLengths

interface ExcerptProps {
  as?: React.ElementType
  children?: string | null | undefined
  lines?: lineLengthsType
  className?: string
}

const Excerpt = ({
  as: Component = "div",
  children,
  lines = "3",
  className,
}: ExcerptProps) => {
  if (!children) return null

  return (
    <Component
      className={classNames("excerpt", className, {
        [lineLengths[lines]]: lines,
      })}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[
          [rehypeExternalLinks, { rel: ["noopener"], target: "_blank" }],
        ]}
      >
        {children}
      </ReactMarkdown>
    </Component>
  )
}

Excerpt.propTypes = {
  children: PropTypes.string,
  /**
   * Example
   */
  lines: PropTypes.oneOf(Object.keys(lineLengths)),
  className: PropTypes.string,
}

export default Excerpt

import React from "react"
import TimeAgo from "react-timeago"

import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import Excerpt from "design_system/core/components/excerpt"
import { Item } from "design_system/utils/types/item"
import robotEmoji from "images/pixel_art/emojis/115_robot.png"
import moneyMouthFace from "images/pixel_art/emojis/29_money-mouth face.png"

import Button from "design_system/core/components/button"

const ChangelogListItem = ({
  name,
  slug,
  summary,
  content,
  publishedAt,
  reactions = [],
}: Item) => {
  const href = `/changelog/${slug}`
  return (
    <div>
      <div className="relative">
        <Title
          title={`_${slug}_ ${name}`}
          size="sm"
          href={href}
          className="inline-block [&_em]:rounded [&_em]:font-mono [&_em]:text-xs [&_em]:px-2 [&_em]:py-1"
        />
      </div>
      <p className="text-secondary-600">
        <em>
          Released <TimeAgo date={publishedAt} />
        </em>
      </p>
      <Excerpt className="mt-3 text-base">{summary || content}</Excerpt>

      <div className="mt-4 flex gap-2">
        {reactions.map((reaction) => (
          <Button
            key={reaction.id}
            variant="ghost"
            color="secondary"
            size="sm"
            className="!py-1 !px-2 flex gap-2 divide-x"
          >
            <img
              src={reaction.iconUrl}
              alt={`${reaction.name} emoji`}
              title={reaction.name}
              width={24}
              className="pixel-art"
            />
            <span className="font-mono align-middle pl-2 text-dark">
              {reaction.count}
            </span>
          </Button>
        ))}
      </div>
    </div>
  )
}

export default ChangelogListItem

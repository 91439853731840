import React from "react"

import StaticHomePage from "./static/home/index"

import AdventureIndexPage from "./adventure/index"
import AdventureArtCommissionIndexPage from "./adventure/art_commissions/index"
import AdventureDemoIndexPage from "./adventure/demo/index"

import ChangelogIndexPage from "./changelog/index"
import ChangelogShowPage from "./changelog/show"

import InterviewIndexPage from "./interviews/index"
import InterviewShowPage from "./interviews/show"
import AppShell from "design_system/application/providers/app_shell"

const wrapped = (Component) => {
  return (props, _railsContext) => {
    return () => (
      <AppShell>
        <Component {...props} />
      </AppShell>
    )
  }
}

export default {
  StaticHomePage: wrapped(StaticHomePage),

  AdventureIndexPage: wrapped(AdventureIndexPage),
  AdventureArtCommissionIndexPage: wrapped(AdventureArtCommissionIndexPage),
  AdventureDemoIndexPage: wrapped(AdventureDemoIndexPage),

  ChangelogIndexPage: wrapped(ChangelogIndexPage),
  ChangelogShowPage: wrapped(ChangelogShowPage),

  InterviewIndexPage: wrapped(InterviewIndexPage),
  InterviewShowPage: wrapped(InterviewShowPage),
}

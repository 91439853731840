const elements = {
  earth: {
    id: "earth",
    name: "Earth",
    labelCssName: "text-earth",
  },
  fire: {
    id: "fire",
    name: "Fire",
    labelCssName: "text-fire",
  },
  water: {
    id: "water",
    name: "Water",
    labelCssName: "text-water",
  },
  air: {
    id: "air",
    name: "Air",
    labelCssName: "text-air",
  },
  spirit: {
    id: "spirit",
    name: "Spirit",
    labelCssName: "text-spirit",
  },
}

export default elements

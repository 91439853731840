export const weekdays = {
  sunday: {
    irish: "Dé Domhnaigh",
    english: "Sunday",
  },
  monday: {
    irish: "Dé Luain",
    english: "Monday",
  },
  tuesday: {
    irish: "Dé Máirt",
    english: "Tuesday",
  },
  wednesday: {
    irish: "Dé Céadaoin",
    english: "Wednesday",
  },
  thursday: {
    irish: "Déardaoin",
    english: "Thursday",
  },
  friday: {
    irish: "Dé hAoine",
    english: "Friday",
  },
  saturday: {
    irish: "Dé Sathairn",
    english: "Saturday",
  },
}

export const months = {
  january: {
    irish: "Eanáir",
    english: "January",
  },
  february: {
    irish: "Feabhra",
    english: "February",
  },
  march: {
    irish: "Márta",
    english: "March",
  },
  april: {
    irish: "Aibreán",
    english: "April",
  },
  may: {
    irish: "Bealtaine",
    english: "May",
  },
  june: {
    irish: "Meitheamh",
    english: "June",
  },
  july: {
    irish: "Iúil",
    english: "July",
  },
  august: {
    irish: "Lúnasa",
    english: "August",
  },
  september: {
    irish: "Meán Fómhair",
    english: "September",
  },
  october: {
    irish: "Deireadh Fómhair",
    english: "October",
  },
  november: {
    irish: "Samhain",
    english: "November",
  },
  december: {
    irish: "Nollaig",
    english: "December",
  },
}

export function monthFromInt(int) {
  return months[Object.keys(months)[int]]
}

export function weekdayFromInt(int) {
  return weekdays[Object.keys(weekdays)[int]]
}

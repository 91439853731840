import React from "react"
import ChangelogListTemplate from "design_system/application/templates/changelog"
import { useQuery } from "@apollo/client"
import query from "design_system/application/templates/changelog/query.graphql"

export const ChangelogIndexPage = (props) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: "changelog" },
  })
  return (
    <ChangelogListTemplate
      loading={loading}
      items={loading ? [] : data.collection?.items?.nodes || []}
    />
  )
}

export default ChangelogIndexPage

import React, { useEffect, useRef } from "react"
import { BotUI, BotUIAction, BotUIMessageList } from "@botui/react"
import { createBot } from "botui"
import {
  actionRenderer,
  messageRenderer,
} from "@love-irish/adventure-story-teller"

import "@love-irish/adventure-story-teller/src/components/story_teller/index.css"

import { playScript } from "./script"

const Demo = () => {
  const botRef = useRef(createBot())
  const mybot = botRef.current
  let timer

  useEffect(() => {
    clearTimeout(timer) // makes sure to clear previous call
    timer = setTimeout(() => playScript(mybot), 50)
  }, [mybot])

  return (
    <div>
      <div className="storyPages">
        <div className="storyPagesContainer">
          <hr />
          <hr />
          <hr />
        </div>
      </div>
      <div className="border border-secondary-200 dark:border-secondary-700">
        <BotUI bot={mybot}>
          <BotUIMessageList renderer={messageRenderer} bringIntoView={false} />
          <BotUIAction renderer={actionRenderer} bringIntoView={false} />
        </BotUI>
      </div>
    </div>
  )
}

export default Demo

import React, { useState } from "react"
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core"
import {
  SortableContext,
  horizontalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable"

import Button from "@love-irish/core-button"

import egg from "./egg.png"

import Option from "./option"

const defaultData = {
  options: [
    { id: "2", label: "Gaeilge" },
    { id: "1", label: "Tá" },
    { id: "3", label: "agam" },
  ],
  correctOrder: ["1", "2", "3"],
}

function UnscramblerMiniGame({ data = defaultData, onClick }) {
  const [items, setItems] = useState(data.options.map((o) => o.id))
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return (
    <div className="p-4 grow">
      <header className="flex gap-10 justify-between items-start">
        <div>
          <h1 className="font-mono-header text-5xl leading-none">
            The Unscrambler
          </h1>
          <p>Reorder the words in the correct order to continue</p>
        </div>
        <img src={egg} className="pixel-art" width="64" />
      </header>

      <div>
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            <ul className="flex mt-8 gap-4 justify-center">
              {items.map((id) => {
                const { label } = data.options.find((o) => o.id === id)
                return (
                  <Option key={id} id={id}>
                    {label}
                  </Option>
                )
              })}
            </ul>
            <div className="mt-6 text-right">
              <Button
                onClick={onClick}
                disabled={
                  JSON.stringify(data.correctOrder) !== JSON.stringify(items)
                }
              >
                Continue
              </Button>
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  )
}

export default UnscramblerMiniGame

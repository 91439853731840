import React from "react"

const SkeletonLoader = () => {
  return (
    <div role="status" className="max-w-sm animate-pulse">
      <div className="h-2.5 bg-secondary-300 rounded-full dark:bg-secondary-700 max-w-[100%] mb-2.5"></div>
      <div className="h-2.5 bg-secondary-300 rounded-full dark:bg-secondary-700 max-w-[90%]"></div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default SkeletonLoader

import React from "react"
import useSWR from "swr"
import FeaturedInterviewComponent from "design_system/application/components/featured_interview"
import apiRoutes from "design_system/utils/routes"
import normalize from "json-api-normalize"
import fetch from "cross-fetch"

async function fetchResource({ _options }) {
  const res = await fetch(apiRoutes.interviewsPath() + "?scope=featured")

  if (!res.ok) {
    throw new Error("Failed to fetch data")
  }

  return res.json()
}

const FeaturedInterview = () => {
  const { data, error, isLoading } = useSWR(
    apiRoutes.interviewsPath() + "?scope=featured",
    fetchResource
  )

  let featuredInterview, question, answer
  if (isLoading) {
    featuredInterview = {
      loading: true,
    }
  }

  if (error) return null

  if (data) {
    const interviews =
      normalize(data).get([
        "id",
        "name",
        "slug",
        "summary",
        "content",
        "publishedAt",
        "moderatedAt",
        "updatedAt",
        "questions.id",
        "questions.name",
        "questions.content",
        "questions.source",
        "questions.featured",
        "questions.answers",
        "questions.answers.id",
        "questions.answers.name",
        "questions.answers.content",
      ]) || []
    featuredInterview = interviews[0] || {}
    question =
      (featuredInterview?.questions || []).find(
        (question) => question.featured
      ) || {}
    answer = question.answers && question.answers[0]
  }
  if (!answer || !question || !featuredInterview) return null
  // const interview = {
  //   slug: "robert-o-driscoll",
  //   title: "Robert O'Driscoll",
  //   summary: "Ireland’s Consul General to Western US",
  //   publishedAt: "2020-01-01T18:25:43.511Z",
  //   moderatedAt: "2020-01-20T18:25:43.511Z",
  //   answer: {
  //     title: "_Ní neart go cur le chéile_ which means “there is no strength without unity”",
  //     summary: "I speak a lot about Team Ireland in Western US and in that I mean the Irish government agencies, our local partners and of course the Irish and Irish American communities across the western United States.  In that context, my favorite Irish phrase is 'Ní neart go cur le chéile' which means 'there is no strength without unity.'",
  //   },
  //   question: {
  //     pid: "h9n7hk10dmkvlwui",
  //     title: "What is your favorite Irish word or phrase?",
  //     source: "moderator",
  //   },
  // }
  // const featuredQuestion = featuredInterview?.questions?.select(question => question.featured)

  return (
    <FeaturedInterviewComponent
      {...featuredInterview}
      answer={answer}
      question={question}
    />
  )
}

export default FeaturedInterview

import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import "./index.css"

export const sizes = {
  xs: "badge-xs",
  sm: "badge-sm",
  md: "badge-md",
  lg: "badge-lg",
  xl: "badge-xl",
}

export const colors = {
  primary: "badge-primary",
  secondary: "badge-secondary",
  info: "badge-info",
  success: "badge-success",
  warning: "badge-warning",
  error: "badge-error",
  accent: "badge-accent",
}

export const variants = {
  solid: "badge-solid",
  soft: "badge-soft",
  ghost: "badge-ghost",
  outline: "badge-outline",
}

export const types = {
  link: "badge-link",
}

const variantClass = (variant) => {
  let classString = ""

  switch (typeof variant) {
    case "string":
      classString = variants[variant]
      break
    case "object":
      classString = variant.map((v) => variantClass(v))
      break
    default:
      break
  }

  return classString
}

const Badge = React.forwardRef(
  (
    {
      as: Component = "span",
      children,
      className,
      color = "primary",
      disabled,
      loading,
      onClick,
      size,
      type,
      variant = "solid",
      ...props
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        /* eslint-disable-next-line react/button-has-type */
        disabled={disabled}
        className={classNames(
          "badge",
          className,
          {
            [sizes[size]]: size,
            [colors[color]]: color,
            [types[type]]: type,
            loading,
          },
          variantClass(variant)
        )}
        onClick={onClick}
        {...props}
      >
        {children}
      </Component>
    )
  }
)

Badge.displayName = "Badge"

Badge.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(colors)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(sizes)),
  variant: PropTypes.oneOf(Object.keys(variants)),
}

export default Badge

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import a from "images/celtic/drop_caps/a.svg"
import b from "images/celtic/drop_caps/b.svg"
import c from "images/celtic/drop_caps/c.svg"
import d from "images/celtic/drop_caps/d.svg"
import e from "images/celtic/drop_caps/e.svg"
import f from "images/celtic/drop_caps/f.svg"
import g from "images/celtic/drop_caps/g.svg"
import h from "images/celtic/drop_caps/h.svg"
import i from "images/celtic/drop_caps/i.svg"
import j from "images/celtic/drop_caps/j.svg"
import k from "images/celtic/drop_caps/k.svg"
import l from "images/celtic/drop_caps/l.svg"
import m from "images/celtic/drop_caps/m.svg"
import n from "images/celtic/drop_caps/n.svg"
import o from "images/celtic/drop_caps/o.svg"
import p from "images/celtic/drop_caps/p.svg"
import q from "images/celtic/drop_caps/q.svg"
import r from "images/celtic/drop_caps/r.svg"
import s from "images/celtic/drop_caps/s.svg"
import t from "images/celtic/drop_caps/t.svg"
import u from "images/celtic/drop_caps/u.svg"
import v from "images/celtic/drop_caps/v.svg"
import w from "images/celtic/drop_caps/w.svg"
import x from "images/celtic/drop_caps/x.svg"
import y from "images/celtic/drop_caps/y.svg"
import z from "images/celtic/drop_caps/z.svg"

export const letters = {
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  m,
  n,
  o,
  p,
  q,
  r,
  s,
  t,
  u,
  v,
  w,
  x,
  y,
  z,
}

export const sizes = {
  xs: 50,
  sm: 100,
  md: 150,
  lg: 200,
  xl: 250,
}

const alignments = {
  left: "float-left",
  right: "float-right",
}

const CelticDropCap = ({ align = "left", className, letter, size = "md" }) => {
  return (
    <img
      className={classNames("inline", className, alignments[align])}
      style={{ height: sizes[size], width: "auto" }}
      src={letters[letter]}
      aria-hidden={true}
      alt={letter}
    />
  )
}

CelticDropCap.propTypes = {
  align: PropTypes.oneOf(Object.keys(alignments)),
  /**
   * Only lower case letters. We do not support any numbers or other punctuation marks.
   */
  letter: PropTypes.oneOf(Object.keys(letters)),
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
}

export default CelticDropCap

import React from "react"
import PropTypes from "prop-types"
import Icon from "design_system/core/components/icon"

const BreadCrumbDivider = ({ key, ...props }) => {
  return (
    <li key={key} className="leading-none align-middle">
      <Icon name="chevronRight" {...props} className="text-secondary-300" />
    </li>
  )
}

BreadCrumbDivider.argTypes = {
  key: PropTypes.string,
}

export default BreadCrumbDivider

import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import "./index.css"
export const sizes = {
  xs: "btn-xs",
  sm: "btn-sm",
  md: "btn-md",
  lg: "btn-lg",
  xl: "btn-xl",
}

export const colors = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  info: "btn-info",
  success: "btn-success",
  warning: "btn-warning",
  error: "btn-error",
  accent: "btn-accent",
}

export const variants = {
  solid: "btn-solid",
  soft: "btn-soft",
  ghost: "btn-ghost",
  outline: "btn-outline",
}

export const types = {
  button: "button",
  submit: "submit",
  reset: "reset",
}

const variantClass = (variant) => {
  let classString = ""

  switch (typeof variant) {
    case "string":
      classString = variants[variant]
      break
    case "object":
      classString = variant.map((v) => variantClass(v))
      break
    default:
      break
  }

  return classString
}

const Button = React.forwardRef(
  (
    {
      as: Component = "button",
      children = null,
      className,
      color = "primary",
      disabled,
      loading,
      onClick,
      size,
      type = "button",
      variant = "solid",
      ...props
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        /* eslint-disable-next-line react/button-has-type */
        type={types[type]}
        disabled={disabled}
        className={classNames(
          "btn",
          className,
          {
            [sizes[size]]: size,
            [colors[color]]: color,
            loading,
          },
          variantClass(variant)
        )}
        onClick={onClick}
        {...props}
      >
        {children}
      </Component>
    )
  }
)

Button.displayName = "Button"

Button.propTypes = {
  as: PropTypes.oneOf(["a", "button"]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(colors)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(sizes)),
  type: PropTypes.oneOf(Object.keys(types)),
  variant: PropTypes.oneOf(Object.keys(variants)),
}

export default Button

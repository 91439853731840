"use client"

import React from "react"
import classNames from "classnames"

import logo from "images/brand/logos/wordmark.svg"
import logoDarkMode from "images/brand/logos/wordmark-white.svg"
import UserAvatar from "./user_avatar"
import { ConnectedComponent as CurrentAppVersion } from "design_system/application/components/current_app_version"

import "./index.css"
import { GraphqlProvider } from "design_system/utils/graphql_client"
import { IconExternalLink } from "@tabler/icons-react"

const navigation = [
  // { name: "Interviews", href: "/interviews", translated: "Agallaimh" },
  // { name: "Blog", href: "/blog", translated: "Blag" },

  // { name: "Irish Language Week", href: '/', translated: "Seachtain na Gaeilge" },
  // { name: "About us", href: "/about-us", translated: "Fúinn" },
  // { name: "Library", href: routes.libraryPath(), translated: "leabharlann" },
  // { name: "Events", href: routes.eventsPath(), translated: "cás" },
  // { name: "Pub", href: routes.pubPath(), translated: "teach óil" },
  { name: "Community", href: "/community", translated: "Pobal" },
  { name: "Resources", href: "/resources", translated: "Seachaidh" },
  { name: "Adventure", href: "/adventure", translated: "Eachtra" },
  // {
  //   name: "Shop",
  //   href: "https://shop.love.irish",
  //   translated: "Síopa",
  //   target: "_blank",
  //   rel: "noopener noreferrer",
  // },
  // { name: "More", href: "https://shop.love.irish", translated: "Níos mo" },
]

export default ({ currentUser, currentPath = "/" }) => {
  const handleLinkClick = ({ label, href, section }) => null
  // analytics.trackLinkClick({ label, href, location: ["header", section] })

  return (
    <header className="app-header">
      <nav>
        <div className="container">
          <div className="flex justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center py-3">
                <a href="/">
                  <img
                    className="h-8 w-auto dark:hidden"
                    src={logo}
                    alt="Love.irish"
                  />
                  <img
                    className="h-8 w-auto hidden dark:!block"
                    src={logoDarkMode}
                    alt="Love.irish"
                  />
                </a>
                <div className="inline-block self-start">
                  <CurrentAppVersion />
                </div>
              </div>
              <div className="flex flex-shrink-0 items-center">
                <div className="flex items-center justify-between px-4 py-3 sm:px-6 md:justify-start md:space-x-10 ml-6">
                  <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
                    <nav className="flex space-x-10">
                      {navigation.map(({ name, translated, href, ...rest }) => {
                        const isActive = currentPath.startsWith(href)

                        return (
                          <a
                            key={name}
                            href={href}
                            onClick={() =>
                              handleLinkClick({
                                label: name,
                                section: "main_nav",
                                href,
                              })
                            }
                            className={classNames("app-nav-link", {
                              active: isActive,
                            })}
                            {...rest}
                          >
                            <span>
                              <span
                                className={classNames(
                                  "text-md block font-medium text-dark-700",
                                  { "font-extrabold": isActive }
                                )}
                              >
                                {name}{" "}
                                {href?.includes("http") && (
                                  <IconExternalLink
                                    className="inline align-top text-secondary"
                                    size={12}
                                  />
                                )}
                              </span>
                              <em className="text-xs">{translated}</em>
                            </span>
                          </a>
                        )
                      })}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <UserAvatar currentUser={currentUser} />
          </div>
        </div>
      </nav>
    </header>
  )
}

import React from "react"
import { Menu } from "@headlessui/react"
import Tooltip from "design_system/core/components/tooltip"
import defaultAvatar from "images/avatar/smiley/avatar@2x.png"
import Icon from "design_system/core/components/icon"
import isEmpty from "lodash/isEmpty"

const SettingsLink = () => (
  <a href="/settings" className="inline-block">
    <span className="align-middle">Settings</span>
  </a>
)

const ProfileLink = ({ username }) => (
  <a
    href={username ? `/@${username}` : "/settings/username"}
    className="inline-block"
  >
    <span className="align-middle">Profile</span>
  </a>
)

const LoginLink = ({ username }) => (
  <a href={"/login"} className="inline-block">
    <span className="align-middle">Login</span>
  </a>
)

const LearnMore = ({ username }) => (
  <a href={"/about-us"} className="inline-block">
    <span className="align-middle">Learn more</span>
  </a>
)

const CreateAccountLink = ({ username }) => (
  <a href={"/signup"} className="inline-block">
    <span className="align-middle">Signup</span>
  </a>
)

const LoggedOutWelcomeMessage = () => (
  <div className="px-1">
    Welcome{" "}
    <Tooltip
      className="text-center"
      title="You just learned &rdquo;my friend&ldquo; in Irish! ☘️"
      placement="bottom"
    >
      <strong className="font-medium text-black dark:text-white text-definition">
        a chara
      </strong>
    </Tooltip>
  </div>
)
const LoggedInWelcomeMessage = () => <div className="px-1">Welcome back!</div>

const UserAvatar = ({ currentUser }) => {
  const loggedOut = isEmpty(currentUser)
  return (
    <div className="hidden sm:ml-6 sm:flex sm:items-center">
      <div className="text-right">
        {loggedOut ? <LoggedOutWelcomeMessage /> : <LoggedInWelcomeMessage />}
        {loggedOut ? (
          <div className="px-1 text-xs mt-1">
            <LearnMore />
          </div>
        ) : (
          <div className="px-1 text-xs mt-1">
            <SettingsLink />
            {
              // <SettingsLink /> &middot;{" "}
              // <ProfileLink username={currentUser?.username} />
            }
          </div>
        )}
      </div>

      <div className="relative ml-3" id="user-menu">
        <div id="user-menu-toggler">
          <div
            id="user-menu-toggle"
            variant="ghost"
            color="secondary"
            className="flex max-w-xs items-center rounded-full text-sm focus:outline-none bg-transparent p-1"
          >
            <span className="sr-only">Open user menu</span>
            <img className="h-8 w-8 rounded-full" src={defaultAvatar} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserAvatar

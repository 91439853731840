import React from "react"
import classnames from "classnames"

import {
  IconAlertTriangle,
  IconAt,
  IconBackpack,
  IconBell,
  IconBook,
  IconBusinessplan,
  IconCameraSelfie,
  IconCaretRight,
  IconChevronRight,
  IconCode,
  IconSettingsCog,
  IconCreditCard,
  IconDog,
  IconExternalLink,
  IconHeart,
  IconHome,
  IconLink,
  IconLock,
  IconLogout2,
  IconMoodSmileBeam,
  IconNumber9,
  IconSquareRoundedNumber9,
  IconPalette,
  IconSettings,
  IconUserCircle,
} from "@tabler/icons-react"

const icons = {
  alertTriangle: IconAlertTriangle,
  at: IconAt,
  backpack: IconBackpack,
  bell: IconBell,
  book: IconBook,
  businessPlan: IconBusinessplan,
  cameraSelfie: IconCameraSelfie,
  code: IconCode,
  caretRight: IconCaretRight,
  chevronRight: IconChevronRight,
  creditCard: IconCreditCard,
  dog: IconDog,
  externalLink: IconExternalLink,
  heart: IconHeart,
  home: IconHome,
  link: IconLink,
  lock: IconLock,
  logout2: IconLogout2,
  moodSmileBeam: IconMoodSmileBeam,
  number9: IconNumber9,
  palette: IconPalette,
  settings: IconSettings,
  settingsCog: IconSettingsCog,
  squareRoundedNumber9: IconSquareRoundedNumber9,
  userCircle: IconUserCircle,
}

const sizes = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 32,
  xl: 42,
  xxl: 64,
}

export default ({ name, size: tshirtSize, className, ...props }) => {
  const Component = icons[name]

  if (!Component) {
    return null
  }

  return (
    <Component
      {...props}
      size={sizes[tshirtSize] || tshirtSize}
      className={classnames("inline-block", className)}
    />
  )
}

import React from "react"
import InterviewQuestionItem from "design_system/application/components/interview_question_item"

const InterviewQuestionList = ({ questions = [] }) => {
  return (
    <div id="questions">
      <h2 className="h3 font-normal font-fancy">Ceisteanna</h2>
      <p className="mt-0 text-sm mb-3 text-secondary-500 font-normal">
        &ldquo;Questions&ldquo;
      </p>
      {!questions.length && (
        <div className="mt-5">
          <p>No questions yet–please check back soon.</p>
        </div>
      )}
      <div className="divide-y -ml-5 mt-5">
        {questions.map((question) => {
          return (
            <InterviewQuestionItem
              key={question.id}
              id={question.id}
              {...question}
            />
          )
        })}
      </div>
    </div>
  )
}

InterviewQuestionList.propTypes = {}

export default InterviewQuestionList

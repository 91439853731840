import React from "react"
import AppFooter from "design_system/application/components/app_footer"
import AppShell from "design_system/application/providers/app_shell"

export default (props, railsContext) => {
  return () => (
    <AppShell>
      <AppFooter {...props} currentPath={railsContext.pathname} />
    </AppShell>
  )
}

import React from "react"

import facebookIcon from "images/brand_icons/pixel/24x24/facebook.png"
import { ProfileSocialLinkProps, types } from "./index"
import ProfileSocialLinkTemplate from "./template"

interface FacebookProps extends Omit<ProfileSocialLinkProps, "type"> {}

const Facebook = ({ profile, label = "Facebook", ...args }: FacebookProps) => {
  return (
    <ProfileSocialLinkTemplate
      site="Facebook"
      label={label}
      username={profile.facebookUsername}
      href={`https://facebook.com/profiles/${profile.facebookUsername}`}
      icon={facebookIcon}
      profile={profile}
      {...args}
    />
  )
}

export default Facebook

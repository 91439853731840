import React, { useEffect } from "react"
import Title from "design_system/core/components/title"
import { IconMoodSad, IconSparkles, IconVolume } from "@tabler/icons-react"
import classNames from "classnames"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import useSound from "use-sound"
import "./index.css"
import Excerpt from "design_system/core/components/excerpt"
import SpriteAnimator from "design_system/core/hooks/use_sprite_animation/component"
const SpellCastBlock = ({
  className,
  children,
  previousDisplayCount = 0,
  item,
  ...props
}) => {
  const [play] = useSound(item.audioUrl || "")

  const handlePlay = (e) => {
    e.stopPropagation()
    play()
  }
  return (
    <div className="min-w-[300px]">
      <div className="px-4 pt-4 text-2xs text-dark flex gap-1 items-center">
        <IconSparkles
          className="text-dark inline-block align-middle"
          size={14}
        />
        <span className="align-middle inline-block">Spell cast</span>
      </div>
      <div className="">
        <div className="text-center">
          {item.sprite && (
            <SpriteAnimator
              className="inline-block"
              sprite={item.sprite}
              frameCount={4}
              shouldAnimate
              scale={3}
              fps={5}
              {...(item.spriteSettings || {})}
            />
          )}
        </div>
        <p
          className="post-title text-2xl text-definition px-4 pb-6 pt-3"
          {...props}
        >
          {item.irishName || item.name}
        </p>
      </div>

      <div className="px-4 pb-4">
        {item.irishName && (
          <>
            <div className="">
              <p className="text-xs text-secondary uppercase mb-2">
                Translation:
              </p>
              <Content
                content={item.name}
                className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
              />
            </div>
            <hr className="w-12 my-4" />
          </>
        )}

        {item.summary && (
          <div className="">
            <Content
              content={item.summary}
              className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
            />
          </div>
        )}
      </div>

      <div className="p-3 text-xs  rounded-b border-t border-dark flex gap-2 bg-secondary-50 text-secondary-600 dark:bg-dark-900 dark:text-secondary-100 dark:border-dark-500">
        <IconMoodSad
          className="text-secondary-400 inline-block align-middle"
          size={14}
        />
        <div>It had no effect!</div>
      </div>
    </div>
  )
}

export default SpellCastBlock

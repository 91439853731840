import React from "react"
import { useQuery } from "@apollo/client"
import TimeAgo from "react-timeago"
import { IconMoodPlus } from "@tabler/icons-react"

import Title from "design_system/core/components/title"
import query from "./query.graphql"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import { Item } from "design_system/utils/types/item"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import Badge from "design_system/core/components/badge"
import Tooltip from "design_system/core/components/tooltip"
import SkeletonLoader from "design_system/core/components/skeleton_loader/content"
import TitleSkeletonLoader from "design_system/core/components/skeleton_loader/title"

interface ChangelogDetailsTemplateProps {
  loading: boolean
  item: Item
}

const ReactionSection = ({ item }) => (
  <div className="flex gap-2 pt-8">
    {item.reactions?.map((reaction) => (
      <Button
        key={reaction.id}
        variant="ghost"
        color="secondary"
        size="sm"
        className="!py-1 !px-2 flex gap-2 divide-x"
      >
        <img
          src={reaction.iconUrl}
          alt={`${reaction.name} emoji`}
          title={reaction.name}
          width={24}
          className="pixel-art"
        />
        <span className="font-mono align-middle pl-2 text-dark">
          {reaction.count}
        </span>
      </Button>
    ))}
    <Tooltip title="Add reaction" wrapperClass="ml-4">
      <Button
        variant="outline"
        color="secondary"
        size="xs"
        className="flex gap-2 divide-x"
      >
        <IconMoodPlus size={18} />
      </Button>
    </Tooltip>
  </div>
)

const ChangelogDetailsTemplate = ({
  loading,
  item,
}: ChangelogDetailsTemplateProps) => {
  return (
    <div className="container grid gap-10 grid-cols-12 mt-6 justify-between">
      <div className="col-span-12">
        <PageBreadcrumb pages={[{ name: "Changelog", href: "/changelog" }]} />
      </div>
      <div className="col-span-10 col-start-2 lg:col-span-6 lg:col-start-4">
        <header className="flex justify-between flex-col">
          <div>
            {loading && (
              <div className="mt-24">
                <TitleSkeletonLoader />
              </div>
            )}

            {!loading && (
              <div className="mt-8 break-words">
                <Badge variant="ghost">{item.slug}</Badge>
                <Title
                  title={item.name}
                  size="lg"
                  className="[&_em]:rounded [&_em]:font-mono [&_em]:text-xl [&_em]:px-2 [&_em]:py-1 [&_em]:align-baseline [&_em]:inline-block"
                />
                <p className="text-secondary-600 mt-1">
                  <em>
                    Released <TimeAgo date={item.publishedAt} /> by @myk
                  </em>
                </p>
              </div>
            )}
          </div>
        </header>
        <div className="grid gap-8 divide-y mt-20">
          {loading && (
            <div className="mt-24">
              <SkeletonLoader />
            </div>
          )}
          {!loading && (
            <>
              <Content content={item.content} dropcap={"md"} />
              {/*<ReactionSection item={item} />*/}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const ConnectedComponent = ({ slug }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug },
  })
  return (
    <ChangelogDetailsTemplate
      loading={loading}
      item={loading ? {} : data.item}
    />
  )
}

export default ChangelogDetailsTemplate

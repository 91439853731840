import React from "react"
import fetch from "cross-fetch"

import {
  InMemoryCache,
  ApolloClient,
  ApolloProvider,
  HttpLink,
} from "@apollo/client"

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    fetch,
    credentials: "include",
  }),
  ssrMode: typeof window === "undefined",
})

export const GraphqlProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

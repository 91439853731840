import React from "react"

import Tooltip from "design_system/core/components/tooltip"
import { IconExternalLink } from "@tabler/icons-react"
import { ProfileSocialLinkProps, types } from "./index"

interface TemplateLinkProps extends Omit<ProfileSocialLinkProps, "type"> {
  username: string
  href: string
  icon: string
  site: string
}

const ProfileSocialLinkTemplate = ({
  site,
  label,
  username,
  href,
  icon,
  showTooltip = true,
  showLabel = true,
}: TemplateLinkProps) => {
  const labelText = label === "username" ? username : site

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Profile link for ${site}`}
    >
      <Tooltip
        title={showTooltip && (!showLabel || label === "username") && site}
        wrapperClass="align-middle"
      >
        <img
          src={icon}
          width={24}
          alt={`${site} icon`}
          className="rounded align-middle inline-block pixel-art"
        />
      </Tooltip>
      {showLabel && (
        <>
          <span className="ml-1 align-middle">{labelText}</span>
          <IconExternalLink
            size={16}
            className="ml-1 align-top text-gray-300 leading-none inline-block"
          />
        </>
      )}
    </a>
  )
}

export default ProfileSocialLinkTemplate

import React from "react"
export default function (children, renderSeparator, options = {}) {
  const items = React.Children.toArray(children)

  return items.reduce((result, child, index) => {
    if (index < items.length - 1) {
      return result.concat([
        child,
        renderSeparator({ key: `${index}-separator`, ...options }),
      ])
    }

    return result.concat(child)
  }, [])
}

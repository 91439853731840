import React from "react"
import Tooltip from "design_system/core/components/tooltip"
import Button from "design_system/core/components/button"
import {
  IconLayoutGridAdd,
  IconHeartFilled,
  IconUsers,
  IconRss,
  IconConfetti,
} from "@tabler/icons-react"
import AddNewWidgetCard from "./add_new_widget_card"
import ActivityFeed from "./activity_feed"
import GreetingHeader from "./greeting_header"
import ProfileCard from "design_system/community/components/profile_card"
import { monthFromInt, weekdayFromInt } from "design_system/libs/i18n/ga"
import DateHeader from "design_system/application/templates/user_dashboard/date_header"

const AppUserDashboardTemplate = ({
  currentUser = {},
  currentDate = new Date(),
}) => {
  console.log("currentUser", currentUser)
  return (
    <section className="py-16 mt-8">
      <header className="container">
        <div className="text-center">
          <DateHeader currentUser={currentUser} currentDate={currentDate} />
          <GreetingHeader currentUser={currentUser} date={currentDate} />
        </div>
        <div className="text-right hidden">
          <Button
            variant="ghost"
            color="warning"
            className="!border-dashed border border-secondary-300 hover:border-warning-300 hover:!bg-warning-50"
          >
            <IconLayoutGridAdd size={20} className="mr-1" />
            Customize
          </Button>
        </div>
      </header>

      <main className="container mt-16">
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-4">
            <div className="hidden">
              <ProfileCard profile={currentUser.profile || {}} />
            </div>
          </div>

          <div className="col-span-4">
            <div className="card h-full">
              <h2 className="card-title">
                <IconConfetti className="inline-block mr-2" size={20} />
                Getting started
              </h2>
              <div className="card-body mt-3">
                <ul className="grid gap-3">
                  <li>Choose a username to create your profile</li>
                  <li>Open your welcome gift in your inventory</li>
                  <li>Follow some friends from the community</li>
                  <li>
                    Create your first collection to help organize resources you
                    want to bookmark
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <div className="card h-full hidden">
              <h2 className="card-title">
                <IconUsers className={`inline-block mr-2`} size={20} />
                Friends list
              </h2>
              <div className="card-body mt-3">
                <div className="grid gap-4 grid-cols-2 auto-rows-min divide-x">
                  <div className="">
                    <h3 className="mb-3">
                      <strong>
                        <IconHeartFilled
                          floodColor="red"
                          className="inline text-error"
                          size={12}
                        />{" "}
                        Naoi mo chroí
                      </strong>{" "}
                      (Top 9)
                    </h3>
                    <ul className="grid gap-2 grid-cols-3">
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                      <li>
                        <div className="aspect-square bg-secondary-50 border border-secondary-300"></div>
                      </li>
                    </ul>
                    <div className="mt-4 flex gap-2 justify-end">
                      <Button size="xs" variant="ghost">
                        Reset
                      </Button>
                      <Tooltip title="Would appear when changes were made">
                        <Button size="xs">Save</Button>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="overflow-y-auto flex-auto pl-3">
                    <h3 className="mb-1">All friends</h3>
                    <ul className="grid gap-2 overflow-y-auto max-h-[250px] divide-y">
                      {[
                        "@myk",
                        "@laura",
                        "@blocktv",
                        "@tglurgan",
                        "@example",
                        "@foo",
                        "@bar",
                      ].map((username) => (
                        <li
                          className="flex items-center gap-2 pt-2"
                          key={username}
                        >
                          <div className="aspect-square bg-secondary-50 border border-secondary-300 h-8 w-8"></div>
                          <div>
                            <a href="#">{username}</a>
                            <p className="text-xs text-secondary">
                              Add to top-9
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-7 hidden">
            <h2 className="card-title mb-6 mt-12">
              <IconRss className="inline-block mr-2" size={20} />
              Activity feed
            </h2>
            <ActivityFeed />
          </div>

          <div className="col-span-5 hidden">
            <AddNewWidgetCard />
          </div>
        </div>
      </main>
    </section>
  )
}
export default AppUserDashboardTemplate

import sprite from "./sprite.png"
import icon16 from "./icon_16.png"
import icon32 from "./icon_32.png"

import elements from "design_system/adventure/codex/elements"

const Character = {
  type: "creature",
  name: "Pixie",
  irishName: "Píobaire",
  element: elements.air.id,
  sprite,
  spriteAnimations: ["idle", "walk", "attack", "damage", "death"],
  portraits: {
    idle: {
      16: icon16,
      32: icon32,
    },
  },
}

export default Character

import React, { useMemo } from "react"
import wrapAmpersand from "design_system/utils/ampersand_wrapper"
import { FiLink } from "react-icons/fi"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useRemarkSync } from "react-remark"
import rehypeRaw from "rehype-raw"
import RemarkDropcap from "design_system/utils/remark_dropcap"
import CelticDropCap from "design_system/application/components/celtic_drop_cap"

import "./index.css"

const formatterOptions = {
  allowedAttributes: {
    span: ["class"],
  },
}

export const sizes = {
  xs: "text-base",
  sm: "text-xl",
  md: "text-3xl",
  lg: "text-6xl",
  xl: "text-8xl",
}

export function stringFromUrl(url) {
  if (typeof url === "string") return url
  if (typeof url === "object" && url.hash) return `#${url.hash}`
}

// eslint-disable-next-line react/prop-types
const LinkedTitle = ({ href, title, remarkPlugins, ...props }) => {
  const hrefString = stringFromUrl(href)
  const Component = "a"
  return (
    <Component href={hrefString}>
      <Title title={title} style={{ marginRight: ".3em" }} {...props} />
      <FiLink
        className="inline text-gray-300 group-hover:text-gray-400 transition ease-in-out align-middle h4 "
        style={{ fontSize: ".7em" }}
      />
    </Component>
  )
}

// eslint-disable-next-line react/prop-types
const Title = ({ title, className, dropcap = false, ...props }) => {
  const remarkPlugins = []
  if (dropcap) remarkPlugins.push(RemarkDropcap)

  const reactContent = useMemo(() => {
    return useRemarkSync(wrapAmpersand(title || ""), {
      remarkPlugins,
      remarkToRehypeOptions: { allowDangerousHtml: true },
      rehypePlugins: [rehypeRaw],
      rehypeReactOptions: {
        components: {
          p: React.Fragment,
          a: React.Fragment,
          span: (props) => {
            if (props.className === "dropcap" && dropcap) {
              return (
                <CelticDropCap
                  size={typeof dropcap === "string" ? dropcap : "md"}
                  className="m-0 mr-3"
                  letter={props.children[0].toLowerCase()}
                />
              )
            }

            return <span {...props} />
          },
        },
      },
    })
  }, [title, dropcap])

  return (
    <span className={classNames("align-middle", className)} {...props}>
      {reactContent}
    </span>
  )
}

const PostTitle = ({
  title = "",
  href = null,
  size = "md",
  Component = "h1",
  className = "",
  dropcap = null,
}) => {
  if (!title) return null

  return (
    <Component
      className={classNames("post-title", { [sizes[size]]: size }, className)}
    >
      {href ? (
        <LinkedTitle title={title} href={href} dropcap={dropcap} />
      ) : (
        <Title title={title} dropcap={dropcap} />
      )}
    </Component>
  )
}

PostTitle.propTypes = {
  /**
   * Supports simple markdown (**bold** _italic_) but will not transform links
   */
  title: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dropcap: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default PostTitle

import React from "react"
import Demo from "./demo"

const SpellCastingSection = () => {
  return (
    <div className="container grid grid-cols-2 gap-12 py-24 flex-auto">
      <div className="mt-16">
        <div className="sticky top-10">
          <h2 className="text-7xl font-mono-header">Cast spells</h2>
          <p className="text-lg">
            Harness the power of the Irish language to cast spells collected on
            your adventure.
          </p>
          <p className="mt-10">
            <a href="" className="font-bold underline">
              Learn more &rarr;
            </a>
          </p>
        </div>
      </div>
      <div className="">
        <Demo />
      </div>
    </div>
  )
}

export default SpellCastingSection

import {
  useBotUI,
  useBotUIAction,
  useBotUIMessage,
  BotUI,
  BotUIAction,
  BotUIMessageList,
} from "@botui/react"
import React from "react"

import UnscramblerMiniGame from "@love-irish/adventure-unscrambler-minigame"

import Button from "@love-irish/core-button"
import { IconSparkles } from "@tabler/icons-react"

const MiniGameAction = () => {
  const bot = useBotUI()
  const { data } = useBotUIAction()

  console.log(data)
  return (
    <UnscramblerMiniGame
      data={data}
      onClick={() =>
        bot.next(
          {
            item: data.codexRecord,
          },
          { messageType: "codexRecord" }
        )
      }
    />
  )
}

const CastSpellAction = () => {
  const bot = useBotUI()
  const action = useBotUIAction()
  const options = action.data.options
  const [current, setCurrent] = React.useState(options[0]?.item || {})

  return (
    <div className="flex flex-col flex-auto p-3">
      <div className="text-lg font-fancy text-secondary-600 mb-4">
        Select a spell
      </div>
      <div className="flex gap-2 flex-wrap">
        {options.map((option, i) => (
          <Button
            key={i}
            size="sm"
            color={option.item === current ? "primary" : "secondary"}
            variant={option.item === current ? "solid" : "outline"}
            className="flex gap-2 !p-0 text-left !leading-[1]"
            onClick={() => setCurrent(option.item)}
          >
            {option.item && (
              <img
                src={option.item.icons[32]}
                alt={option.item.name}
                className="w-[36px] h-[36px] block pixel-art"
              />
            )}
            <span className="pr-2">
              <span className=" font-bold block mb-0">
                {option.item?.irishName || option.item?.name}
              </span>
              {option.item?.irishName && (
                <em className="text-xs">{option.item?.name}</em>
              )}
            </span>
          </Button>
        ))}
      </div>
      <div className="flex gap-2 justify-end mt-6">
        <Button
          onClick={() =>
            bot.next({ spell: current }, { messageType: "spellCastResponse" })
          }
        >
          <IconSparkles size={16} />
          Cast spell
        </Button>
      </div>
    </div>
  )
}

export const renderer = {
  miniGame: MiniGameAction,
  castSpell: CastSpellAction,
}

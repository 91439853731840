import React from "react"
import AppHeader from "../../design_system/application/components/app_header"
import AppShell from "../../design_system/application/providers/app_shell"

export default (props, railsContext) => {
  return () => (
    <AppShell>
      <AppHeader {...props} currentPath={railsContext.pathname} />
    </AppShell>
  )
}

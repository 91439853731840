import { faker } from "@faker-js/faker"

import { Item } from "design_system/utils/types/item"

let currentId = 0

export function generateItem(attributes = {}): Item {
  currentId++

  return {
    id: currentId,
    createdAt: new Date().toUTCString(),
    updatedAt: new Date().toUTCString(),
    slug: faker.internet.userName(),
    ...attributes,
  }
}

export function generateFullItem(attributes = {}): Item {
  return generateItem({
    name: faker.lorem.sentence(),
    summary: faker.lorem.paragraphs(2, "\n\n"),
    content: faker.lorem.paragraphs(10, "\n\n"),
    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString(),
    publishedAt: faker.date.past().toUTCString(),
    ...attributes,
  })
}

export function generateChangelogItem(attributes = {}): Item {
  return generateItem({
    name: faker.lorem.sentence(),
    slug: `v${faker.number.int({ max: 100 })}.${faker.number.int({
      max: 100,
    })}.${faker.number.int({ max: 100 })}`,
    summary: faker.lorem.paragraphs(2, "\n\n"),
    content: faker.lorem.paragraphs(10, "\n\n"),

    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString(),
    publishedAt: faker.date.past().toUTCString(),

    ...attributes,
  })
}

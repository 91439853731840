import React from "react"
import Button from "design_system/core/components/button"
import AdventureStoryPreview from "@love-irish/adventure-story-preview"
const ExploreDungeonSection = () => (
  <div className="container grid grid-cols-2 gap-10 py-24">
    <div className="mt-16">
      <div className="sticky top-10">
        <h2 className="text-7xl font-mono-header">
          Explore dungeons &amp; stories
        </h2>
        <p className="text-lg">
          Each dungeon has a theme along with creatures and objectives to earn
          rewards.
        </p>
        <p className="mt-10">
          <a href="" className="font-bold underline">
            Learn more &rarr;
          </a>
        </p>
      </div>
    </div>
    <div className="">
      <div className="flex justify-end gap-1">
        <Button size="sm" variant="ghost" disabled>
          &larr;
        </Button>
        <Button size="sm" variant="ghost">
          &rarr;
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-10">
        <div>
          <AdventureStoryPreview />
          <h2 className="font-fancy text-lg mt-4">Doinsiún Uisce</h2>
          <p className="italic text-sm mt-1">
            Dungeon of Water – a calm reef with harmless creatures.
          </p>
          <p className="mt-6">
            <a href="" className="font-bold underline text-sm text-dark-400">
              Explore &rarr;
            </a>
          </p>
        </div>
        <div>
          <AdventureStoryPreview />
          <h2 className="font-fancy text-lg mt-4">Scéal an Rón</h2>
          <p className="italic text-sm mt-1">
            Story of the seal – how a seal saved a village from a fire.
          </p>
          <p className="mt-6">
            <a href="" className="font-bold underline text-sm text-dark-400">
              Read &rarr;
            </a>
          </p>
        </div>
        <div>
          <AdventureStoryPreview />
          <h2 className="font-fancy text-lg mt-4">Doinsiún na Dubh Linn</h2>
          <p className="italic text-sm mt-1">
            Dungeon of the Black Pool – teeming with creatures from the eternal
            deep.
          </p>
          <p className="mt-6">
            <a href="" className="font-bold underline text-sm text-dark-400">
              Explore &rarr;
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ExploreDungeonSection

import React, { useEffect, useState } from "react"
import Title from "design_system/core/components/title"
import { IconBackpack, IconVolume } from "@tabler/icons-react"
import classNames from "classnames"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import useSound from "use-sound"
import "./index.css"
import Excerpt from "design_system/core/components/excerpt"
import logo from "images/brand/logos/short.png"
import themeSong from "./theme-song.mp3"

import clouds from "./clouds_1.png"
import clouds2 from "./clouds_2.png"
import clouds3 from "./clouds_3.png"

const CodexRecord = ({
  className,
  children,
  previousDisplayCount = 0,
  item,
  ...props
}) => {
  const [played, setPlayed] = useState(false)

  const handlePlay = () => setPlayed(true)
  return (
    <div className="text-center px-4 pt-4 pb-6">
      <div className="text-2xs pb-2 text-secondary-600 flex gap-1 justify-between items-center">
        Learn more
      </div>
      <div className="pb-3 flex flex-col justify-center items-center gap-3 z-10 relative">
        <img src={logo} width={50} />
        <p className="post-title text-4xl text-center relative" {...props}>
          Adventure RPG{" "}
          <span className="font-bold font-sans text-xs align-super uppercase text-secondary absolute translate-x-full right-0 pl-2">
            alpha
          </span>
        </p>

        <div className="px-4 pb-4">
          <Content
            content="Encouraging the use of Irish in everyday life and making it fun through games and stories"
            className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
          />
        </div>

        <audio src={themeSong} controls onPlay={handlePlay} />
      </div>
      <div className="clouds">
        <img src={clouds3} alt="" />
        <img src={clouds2} alt="" />
        <img src={clouds} alt="" />
      </div>
    </div>
  )
}

export default CodexRecord

import React from "react"
import classNames from "classnames"
import {
  BsFillCaretDownFill,
  BsFillCaretLeftFill,
  BsFillCaretUpFill,
  BsFillCaretRightFill,
} from "react-icons/bs"

const Arrow = ({ placement, color, colorDark }) => {
  const arrowDefaultClass = "tooltip-arrow"

  const getArrow = () => {
    switch (placement) {
      case "top":
        return (
          <BsFillCaretDownFill
            className={classNames(
              arrowDefaultClass,
              "bottom-[2px] translate-y-full w-full left-0"
            )}
          />
        )
      case "top-start":
        return (
          <BsFillCaretDownFill
            className={classNames(
              arrowDefaultClass,
              "bottom-[2px] translate-y-full left-0 ml-3"
            )}
          />
        )
      case "top-end":
        return (
          <BsFillCaretDownFill
            className={classNames(
              arrowDefaultClass,
              "bottom-[2px] translate-y-full right-0 mr-3"
            )}
          />
        )
      case "right":
        return (
          <BsFillCaretLeftFill
            className={classNames(
              arrowDefaultClass,
              "left-[-6px] top-1/2 transform -translate-y-1/2"
            )}
          />
        )
      case "right-start":
        return (
          <BsFillCaretLeftFill
            className={classNames(arrowDefaultClass, "left-[-6px] top-2")}
          />
        )
      case "right-end":
        return (
          <BsFillCaretLeftFill
            className={classNames(arrowDefaultClass, "left-[-6px] bottom-2")}
          />
        )
      case "bottom":
        return (
          <BsFillCaretUpFill
            size={16}
            height={20}
            className={classNames(
              arrowDefaultClass,
              "top-[-6px] w-full left-0"
            )}
          />
        )
      case "bottom-start":
        return (
          <BsFillCaretUpFill
            className={classNames(arrowDefaultClass, "top-[-6px] left-0 ml-3")}
          />
        )
      case "bottom-end":
        return (
          <BsFillCaretUpFill
            className={classNames(arrowDefaultClass, "top-[-6px] right-0 mr-3")}
          />
        )
      case "left":
        return (
          <BsFillCaretRightFill
            className={classNames(
              arrowDefaultClass,
              "right-[-6px] top-1/2 transform -translate-y-1/2"
            )}
          />
        )
      case "left-start":
        return (
          <BsFillCaretRightFill
            className={classNames(arrowDefaultClass, "right-[-6px] top-2")}
          />
        )
      case "left-end":
        return (
          <BsFillCaretRightFill
            className={classNames(arrowDefaultClass, "right-[-6px] bottom-2")}
          />
        )
      default:
        break
    }
  }

  return getArrow()
}

export default Arrow

import React from "react"
import cn from "design_system/libs/cn"

const LofiString = ({
  width = "w-full max-w-[360px]",
  height = "h-2",
  background = "bg-secondary-200 dark:bg-secondary-700",
  className = "",
}) => {
  return (
    <div className={cn("rounded-full", width, height, background, className)}>
      &nbsp;
    </div>
  )
}

export default LofiString

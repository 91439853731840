import spellData from "design_system/adventure/codex/spells"

export const playScript = (bot, actions = {}) => {
  const spells = Object.entries(spellData)

  return bot.message
    .add({
      text: "It takes time, effort and a lot of practice to become a true seanchaí. ",
    })
    .then(async (res) => {
      await bot.message.add({
        text: "Minigames are a great way to practice your skills and earn rewards. Each might have a different format so be sure to read the instructions carefully! You won't be able to continue until you're done",
      })
    })
    .then(async (res) => {
      const messages = await bot.message.getAll()
      console.log({ messages })
    })
    .then(async (res) => await startMiniGame(bot, actions))
}

const taGaeilgeAgam = {
  options: [
    { id: "2", label: "Gaeilge" },
    { id: "1", label: "Tá" },
    { id: "3", label: "agam" },
  ],
  correctOrder: ["1", "2", "3"],
  codexRecord: {
    name: "Tá Gaeilge agam",
    summary: "I speak Irish",
  },
}

const diaDuit = {
  options: [
    { id: "2", label: "duit" },
    { id: "1", label: "Dia" },
  ],
  correctOrder: ["1", "2"],
  codexRecord: {
    name: "Dia duit",
    summary: "Hello",
  },
}

const isMaithLiomMadra = {
  options: [
    { id: "2", label: "Is" },
    { id: "1", label: "liom" },
    { id: "3", label: "maith" },
    { id: "4", label: "madra" },
  ],
  correctOrder: ["2", "3", "1", "4"],
  codexRecord: {
    name: "Is maith liom madra",
    summary: "I like dogs",
  },
}

const miniGames = [taGaeilgeAgam, diaDuit, isMaithLiomMadra]
const randomMiniGame = () =>
  miniGames[Math.floor(Math.random() * miniGames.length)]

const startMiniGame = async (bot, actions = {}, options = miniGames[0]) => {
  return await bot.action
    .set(options, {
      actionType: "miniGame",
      bringIntoView: false,
    })
    .then(async (res) => {
      await bot.message.add({
        text: "Nice! Try playing another game.",
      })
    })
    .then(async (res) => await startMiniGame(bot, actions, randomMiniGame()))
}

import React from "react"
import { useQuery } from "@apollo/client"

import Title from "design_system/core/components/title"
import query from "./query.graphql"
import SkeletonLoader from "design_system/core/components/skeleton_loader/content"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import InterviewOverview from "design_system/application/components/interview_overview"
import Excerpt from "design_system/core/components/excerpt"
import routes from "design_system/utils/routes"
import Timeago from "react-timeago"
import formatter from "design_system/utils/formatter"
// <img
// src={"/@mykklemme/icon"}
// className="pixel-art inline-block ml-2 mr-1 border border-secondary-200/50"
// width={24}
// height={24}
// />
const List = ({ items, loading = false }) => {
  if (loading) return <SkeletonLoader />
  console.log(items)
  return items.map((item) => {
    return (
      <div key={item.id} className="flex">
        <div className="grid col-span-12 py-8 flex-1">
          <div className="col-span-8">
            <Title
              size="md"
              title={item.name}
              href={routes.interviewPath(item.slug)}
            />
            <Excerpt className="mt-1 text-base">
              {item.summary || item.content}
            </Excerpt>

            <div className="mt-5 relative z-10">
              <a href={routes.interviewPath(item.slug)}>
                <strong>Léigh é</strong> &middot;{" "}
                <em className="text-secondary-600">Read it</em>
              </a>
            </div>

            <div className="text-secondary-600">
              <div className="mt-4 text-xs">
                Moderated by <strong>@mykklemme</strong> on{" "}
                <time className="font-bold">
                  {formatter.shortDate(item.moderatedAt)}
                </time>
              </div>
              <div className="mt-2 text-xs">
                Published on{" "}
                <time className="font-bold">
                  {formatter.shortDate(item.publishedAt)}
                </time>
              </div>
            </div>
          </div>
        </div>
        {item.thumbnail?.url && (
          <img
            src={item.thumbnail.url}
            alt={item.name}
            width={300}
            className="float-right -mt-8 -z-10 relative flex-grow-0 flex-shrink-0"
          />
        )}
      </div>
    )
  })
}

const InterviewListTemplate = ({ items = [], loading = false }) => {
  return (
    <div className="container grid gap-10 grid-cols-12 mt-6">
      <div className="col-span-12">
        <header className="flex justify-between flex-col">
          <div>
            <div>
              <PageBreadcrumb
                pages={[{ name: "Interviews", href: "/interviews" }]}
              />
            </div>
            <div className="mt-16">
              <Title title="Ár n-Agallaimh" size="lg" />
              <p className="mt-1 text-lg mb-3 text-gray-500 font-normal">
                &ldquo;Our Interviews&ldquo;
              </p>
            </div>
          </div>
        </header>
      </div>
      <div className="col-span-8 divide-y">
        <List items={items} loading={loading} />
      </div>

      <aside className="col-span-4 mt-8">
        <InterviewOverview />
      </aside>
    </div>
  )
}

export const ConnectedComponent = (props) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: "changelog" },
  })
  return (
    <InterviewListTemplate
      loading={loading}
      items={loading ? [] : data.collection?.items?.nodes || []}
    />
  )
}

export default InterviewListTemplate

import React from "react"

const AddNewWidgetCard = () => {
  return (
    <div className="card h-full border-dashed text-center h-[150px] flex items-center justify-center hover:border-warning-300 cursor-pointer transition-all group hover:bg-warning-50">
      <h2 className="!text-sm card-title !font-sans group-hover:text-warning-700">
        Add new widget
      </h2>
    </div>
  )
}

export default AddNewWidgetCard

const routes = {
  toUrl: (path) =>
    `${process.env.NEXT_PUBLIC_LOVE_IRISH_APP || "https://love.irish"}${path}`,

  rootPath: () => "/",

  aboutUsPath: () => "/about-us",
  /**
   *
   * @return {string}
   */
  blogPostsPath: () => "/blog",
  /**
   *
   * @param slug
   * @return {`/blog/${string}`}
   */
  blogPostPath: (slug) => `/blog/${slug}`,
  eventsPath: () => "/events",
  /**
   *
   * @param slug
   * @return {`/events/${string}`}
   */
  eventPath: (slug) => `/events/${slug}`,
  faqPath: () => "/faq",
  /**
   *
   * @return {string}
   */
  interviewsPath: () => "/interviews",
  /**
   *
   * @param slug
   * @return {`/interviews/${string}`}
   */
  interviewPath: (slug) => `/interviews/${slug}`,
  libraryPath: () => "/library",
  pubPath: () => "/pub",
  /**
   *
   * @return {string}
   */
  resourcesPath: () => "/resources",
  /**
   *
   * @param slug
   * @return {`/resources/${string}`}
   */
  resourcePath: (slug) => `/resources/${slug}`,
  shopPath: () => "/shop",
  sitemapPath: () => "/sitemap",

  /**
   * Special events
   */
  irishLanguageWeek2023Path: () => routes.eventPath("irish-language-week-2023"),
}

export default routes

import React from "react"
import Demo from "./demo"

const MiniGamesSection = () => {
  return (
    <div className="container grid grid-cols-2 gap-12 py-24 flex-auto">
      <div className="mt-16">
        <div className="sticky top-10">
          <h2 className="text-7xl font-mono-header">Play minigames</h2>
          <p className="text-lg">
            Complete challenges in a WarioWare-inspired format where mini-games
            can be random and whacky like playing a couple rounds of chess or
            unscramble words.
          </p>
          <p className="mt-10">
            <a href="" className="font-bold underline">
              Learn more &rarr;
            </a>
          </p>
        </div>
      </div>
      <div className="">
        <Demo />
      </div>
    </div>
  )
}

export default MiniGamesSection

import React, { useState, useCallback, useRef, useEffect } from "react"
import classNames from "classnames"
import { Popper, Reference, Manager } from "react-popper"
import { motion, AnimatePresence } from "framer-motion"
import Arrow from "./arrow"
import PropTypes from "prop-types"
import { Portal } from "react-portal"

const PopperElement = (props) => {
  const { title, forceUpdate, open } = props
  useEffect(() => {
    if (open) {
      forceUpdate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return <div className="tooltip-content">{title}</div>
}

export const placements = {
  top: "top",
  "top-start": "top-start",
  "top-end": "top-end",
  bottom: "bottom",
  "bottom-start": "bottom-start",
  "bottom-end": "bottom-end",
  right: "right",
  "right-start": "right-start",
  "right-end": "right-end",
  left: "left",
  "left-start": "left-start",
  "left-end": "left-end",
}
const Tooltip = ({
  className = "",
  children,
  title,
  placement = "top",
  wrapperClass = null,
  isOpen = false,
  ...rest
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(isOpen)
  const tooltipNode = useRef()

  const toggleTooltip = useCallback(
    (bool) => {
      if (!tooltipOpen) {
        setTooltipOpen(bool)
      }
    },
    [isOpen]
  )

  useEffect(() => {
    if (isOpen !== undefined) {
      setTooltipOpen(isOpen)
    }
  }, [isOpen])

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <span
            className={classNames("tooltip-wrapper", wrapperClass)}
            ref={ref}
            onMouseEnter={() => !isOpen && toggleTooltip(true)}
            onMouseLeave={() => !isOpen && toggleTooltip(false)}
          >
            {children}
          </span>
        )}
      </Reference>
      {title && tooltipOpen && (
        <Portal>
          <Popper
            placement={placement}
            innerRef={(node) => (tooltipNode.current = node)}
            modifiers={[
              { name: "arrow", options: { element: Arrow } },
              { name: "offset", options: { offset: [0, 7] } },
            ]}
            strategy="fixed"
          >
            {({ ref, style, ...popperProps }) => (
              <AnimatePresence>
                <motion.div
                  className={classNames("tooltip", className)}
                  ref={ref}
                  style={style}
                  initial={{ opacity: 0, visibility: "hidden" }}
                  animate={
                    tooltipOpen
                      ? { opacity: 1, visibility: "visible" }
                      : { opacity: 0, visibility: "hidden" }
                  }
                  transition={{ duration: 0.15, type: "tween" }}
                >
                  <PopperElement
                    open={tooltipOpen}
                    title={title}
                    {...rest}
                    {...popperProps}
                  />
                  <Arrow placement={placement} />
                </motion.div>
              </AnimatePresence>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  )
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placement: PropTypes.oneOf(Object.keys(placements)),
  wrapperClass: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default Tooltip

import { HiHeart } from "react-icons/hi2"
import Typed from "react-typed"
import React from "react"
import Title from "design_system/core/components/title"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"

import exampleProfileIcon from "images/profile_icons/duolingo.png"
import exampleBannerImage from "images/profile_banners/campfire.gif"
import landscapeBeach from "images/profile_banners/landscape_beach.jpeg"
import pixie from "design_system/adventure/codex/characters/pixie"
import SpriteAnimator from "design_system/core/hooks/use_sprite_animation/component"
import SkeletonLoader from "design_system/core/components/skeleton_loader/content"

import avatar1 from "./example_avatars/avatar_1.gif"
import avatar2 from "./example_avatars/avatar_2.webp"
import avatar3 from "./example_avatars/avatar_3.png"
import avatar4 from "./example_avatars/avatar_4.gif"

import duolingoAvatar from "images/profile_icons/duolingo.png"
import starterAvatar from "images/profile_icons/starter.png"
import brandAvatar from "images/profile_icons/brand.png"
import LofiImage from "design_system/core/components/lo_fi/image"
import LofiString from "design_system/core/components/lo_fi/string"
import LofiTitle from "design_system/core/components/lo_fi/title"
import LofiAvatar from "design_system/core/components/lo_fi/avatar"

const inspirationAvatars = [avatar1, avatar2, avatar3, avatar4]
const exampleAvatars = [brandAvatar, starterAvatar, duolingoAvatar]

const ProfilePreview = ({ banner, avatar }) => (
  <div>
    <div>
      {banner ? (
        <img src={banner} alt="" className="pixel-art" />
      ) : (
        <LofiImage height="h-36" background="bg-secondary-100" />
      )}
    </div>
    <div className="px-5">
      <div className="card profile-card -mt-10 inline-block max-w-[200px]">
        <div className="profile-card-body p-3">
          <div className="flex gap-2">
            <div className="w-[32px]">
              {avatar ? (
                <img src={avatar} alt="" className="pixel-art" />
              ) : (
                <LofiAvatar />
              )}
            </div>
            <div className="w-[100px] grid gap-1 pt-1">
              <div>
                <LofiString width="max-w-[70%]" />
              </div>
              <div>
                <LofiString height="h-1.5" />
              </div>
            </div>
          </div>
          <hr className="my-3" />
          <div>
            <div className="grid gap-2">
              <LofiString height="h-1.5" width="max-w-[60%]" />
              <LofiString width="max-w-[40%]" height="h-1.5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default function AppHomepageTemplate({ featuredPost }) {
  return (
    <div className="container grid gap-10 grid-cols-12 mt-6">
      <div className="col-span-12">
        <header className="flex justify-between flex-col">
          <div>
            <div>
              <PageBreadcrumb
                pages={[{ name: "Adventure", href: "/adventure" }]}
              />
            </div>
            <div className="mt-16 text-center">
              <Title title="Samhlaigh linn" size="lg" />
              <p className="mt-1 text-lg mb-3 text-gray-500 font-normal">
                &ldquo;Imagine with us&ldquo;
              </p>
            </div>
          </div>
        </header>
      </div>

      <div className="col-span-12">
        <div className="gap-8 flex justify-around items-end">
          <div className="text-center">
            <img
              src={exampleProfileIcon}
              alt=""
              className="pixel-art inline-block"
              width={64}
            />

            <p className="text-xs text-secondary-600 mt-4">
              <a href="#avatars">Avatars</a>
            </p>
          </div>

          <div className="text-center">
            <img
              src={exampleBannerImage}
              alt=""
              className="pixel-art inline-block"
              width={630}
            />

            <p className="text-xs text-secondary-600 mt-4">Banners</p>
          </div>

          <div className="text-center">
            <SpriteAnimator
              className="inline-block"
              sprite={pixie.sprite}
              width={16}
              height={16}
              frameCount={4}
              shouldAnimate
              scale={3}
              fps={8}
              {...(pixie.spriteSettings || {})}
            />
            <p className="text-xs text-secondary-600 mt-4">&amp; more</p>
          </div>
        </div>
      </div>

      <div className="col-span-12 hidden">
        <hr className="my-12" />
        <h2 className="text-2xl font-fancy mb-3">Our process</h2>
        <div className="prose">
          <p>
            Our goal is to feature artists from our community when we commission
            artwork for things like our adventure system.
          </p>
        </div>

        <ul className="grid gap-2">
          <li>Apply to be an artist (submit profile)</li>
          <li>
            Submit a sketch using our list of ideas or something you came up
            with
          </li>
          <li>Create the art (default contract is up to 2 revisions)</li>
          <li>Get paid</li>
          <li>We will notify you when your art is published on the site</li>
        </ul>
      </div>

      <div className="col-span-12" id="avatars">
        <hr className="my-12" />
        <div className="grid md:grid-cols-2 gap-10">
          <div>
            <h2 className="text-2xl font-fancy mb-5 ">Profile avatars</h2>
            <ul className="grid gap-2">
              <li>Canvas size of 32px by 32px</li>
              <li>Typically rendered at 64px</li>
              <li>Appears on profiles and hover cards</li>
              <li>Animations are supported</li>
              <li>Unlocked by special conditions or in a loot drop</li>
              <li>
                View the{" "}
                <a
                  href="/adventure/rewards/profile-icons"
                  className="font-bold"
                >
                  gallery page
                </a>{" "}
                for available avatars
              </li>
            </ul>

            <h3 className="mb-3 font-bold mt-20">Example avatars</h3>
            <div className="flex gap-4  mt-5">
              {exampleAvatars.map((avatar, index) => (
                <img
                  src={avatar}
                  alt=""
                  className="pixel-art"
                  width={64}
                  key={avatar}
                />
              ))}
            </div>

            <h3 className="font-bold mt-8">Inspiration (not our artwork)</h3>
            <p className="">
              Since we do not have a lot of avatars to show, here are some from
              the web.
            </p>
            <div className="flex gap-4 mt-5">
              {inspirationAvatars.map((avatar, index) => (
                <img
                  src={avatar}
                  alt=""
                  className="pixel-art"
                  width={64}
                  key={avatar}
                />
              ))}
            </div>
          </div>
          <div>
            <p className="text-secondary-600 mb-4">
              Preview of how this appears across the site like profiles.
            </p>
            <ProfilePreview avatar={duolingoAvatar} />
          </div>
        </div>
      </div>

      <div className="col-span-12">
        <hr className="my-12" />
        <div className="grid md:grid-cols-2 gap-10">
          <div>
            <h2 className="text-2xl font-fancy mb-5">Profile banners</h2>
            <ul className="grid gap-2">
              <li>Canvas size of 632px by 320px</li>
              <li>
                Typically rendered at 2x ({632 * 2}px by {320 * 2}px)
              </li>
              <li>Appears on profiles</li>
              <li>Animations are supported</li>
              <li>Unlocked by special conditions or in a loot drop</li>
            </ul>
          </div>
          <div>
            <p className="text-secondary-600 mb-4">
              Preview of how this appears across the site like profiles.
            </p>
            <ProfilePreview banner={landscapeBeach} />
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import PropTypes from "prop-types"
// import analytics from "/assets/utils/analytics"
import classnames from "classnames"

const BreadcrumbItem = ({
  children,
  onClick = () => {},
  href,
  analyticsLabel,
  analyticsLocation,
  ...props
}) => {
  const Component = href ? "a" : "span"
  return (
    <li {...props} className="flex items-center">
      <Component
        href={href}
        onClick={onClick}
        className={classnames("inline-block align-middle leading-none", {
          "hover:text-dark": Boolean(href),
        })}
      >
        <span className="">{children}</span>
      </Component>
    </li>
  )
}

BreadcrumbItem.displayName = "BreadcrumbItem"

BreadcrumbItem.argTypes = {
  key: PropTypes.string.isRequired,
}

export default BreadcrumbItem

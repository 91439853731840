import sprite from "./sprite.png"

import icon32 from "./icon_32.png"
import elements from "design_system/adventure/codex/elements"

const Spell = {
  type: "spell",
  name: "Water dragon",
  irishName: "Dragan uisce",
  summary: "A powerful water spell that summons a water dragon",
  element: elements.water.id,
  spellText: {
    english: "Come forth, water dragon!",
    irish: "Tar aniar, dragan uisce!",
  },
  sprite,
  spriteSettings: {
    frameCount: 15,
    height: 192 / 2,
    width: 192 / 2,
    scale: 4,
  },
  icons: {
    32: icon32,
  },
}

export default Spell

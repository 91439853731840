import posthog from "posthog-js"
// eslint-disable camelcase
const analytics = {
  init: () => {
    if (process.env.NEXT_PUBLIC_POSTHOG_API)
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API, {
        autocapture: false,
        api_host: "https://app.posthog.com",
      })
  },
  track: (event, props = {}) => posthog.capture(event, props),
  trackPageView: () => posthog.capture("$pageview"),
  /**
   *
   * @param label {string} snake_cased string that should have context like blog_post_card when doing a breakdown of link clicks.
   * @param href {url}
   * @param location {[string]} where on the page this components renders (header, footer) that can be used to segment if this link
   * appears more than once on a page
   * @return {CaptureResult | void}
   */

  trackLinkClick: ({ label, href, location = [] }) =>
    posthog.capture("clicked#link", {
      // TODO remove these once we have passed the grace period where this is still used
      link_href: href,
      link_label: label,
      link_location: location,
      component_href: href,
      component_label: label,
      component_location: location,
    }),

  trackFormSubmit: ({ label, success, location = [], ...props }) =>
    posthog.capture("form#submit", {
      component_label: label,
      component_location: location,
      success,
      ...props,
    }),
}
// eslint-enable camelcase

export default analytics

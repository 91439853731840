"use client"

import React from "react"
import Tooltip from "design_system/core/components/tooltip"
import celticKnot from "images/celtic/celtic_knot_heart@2x.png"
import classNames from "classnames"
import PropTypes from "prop-types"

const ContentCloser = ({ scrollToTop, onScrollClick }) => {
  return (
    <div className="relative py-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-gray-500">
          <Tooltip title={scrollToTop && "Go back to top of page"}>
            <span
              className="inline-block"
              onClick={(event) => {
                if (scrollToTop) {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
                onScrollClick?.(event)
              }}
            >
              <img
                src={celticKnot}
                width={30}
                className={classNames("m-0", { "cursor-pointer": scrollToTop })}
                alt="Celtic knot"
              />
            </span>
          </Tooltip>
        </span>
      </div>
    </div>
  )
}

ContentCloser.propTypes = {
  scrollToTop: PropTypes.bool,
}
export default ContentCloser

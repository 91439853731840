import React from "react"
import classNames from "classnames"
import { IconMoodSmile, IconPhoto } from "@tabler/icons-react"

const sizes = {
  md: "w-[32px]",
  lg: "w-[64px]",
} as const

interface LoFiAvatarProps {
  className?: string | null
  size?: keyof typeof sizes
}

const LofiAvatar = ({ size = "lg", className = "" }: LoFiAvatarProps) => {
  return (
    <div
      className={classNames(
        "inline-flex items-center justify-center text-lg aspect-square bg-secondary-100 dark:bg-secondary-900 text-secondary-400 dark:text-secondary-600 max-w-full",
        className,
        {
          [sizes[size]]: size,
        }
      )}
    >
      <IconMoodSmile />
    </div>
  )
}

export default LofiAvatar

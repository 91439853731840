const formatter = {
  date: (time: string | Date) =>
    new Intl.DateTimeFormat("en-US").format(new Date(time)),
  time: (time: string | Date) =>
    new Intl.DateTimeFormat("en-US").format(new Date(time)),

  shortDate: (time: string | Date) => {
    return new Date(time).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
  },
}

export default formatter

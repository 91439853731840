import React from "react"
import Breadcrumb from "design_system/core/components/breadcrumb"
import Icon from "design_system/core/components/icon"
import PropTypes from "prop-types"

const sizes = {
  "2xs": "2xs",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  xxl: "xxl",
  "2xl": "2xl",
}

interface PageBreadcrumbProps extends React.HTMLAttributes<HTMLElement> {
  pages?: Array<{
    href: string
    name: string
  }>
  size?: keyof typeof sizes
}
function PageBreadcrumb({ pages = [], size = "md" }: PageBreadcrumbProps) {
  return (
    <Breadcrumb size={size}>
      <Breadcrumb.Item
        key="home"
        href={"/"}
        className="leading-none"
        analyticsLabel="home"
        analyticsLocation={["page", "breadcrumb"]}
      >
        <Icon
          size="xs"
          name="home"
          className="flex-shrink-0"
          aria-hidden="true"
        />
        <span className="sr-only">Home</span>
      </Breadcrumb.Item>
      {pages.map(({ href, name }) => {
        return (
          <Breadcrumb.Item
            key={name}
            href={href}
            analyticsLocation={["page", "breadcrumb"]}
          >
            {name}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}

PageBreadcrumb.propTypes = {
  /**
   * Tshirt size. Defaults to `sm` since we want this to be subtle on the page instead of the component's default MD size.
   */
  size: PropTypes.oneOf(Object.keys(sizes)),
}

export default PageBreadcrumb

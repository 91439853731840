import React, { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { useStore } from "zustand"

import { jsStoryTellerStore } from "../../stores/story_teller"
import {
  useBotUI,
  useBotUIAction,
  useBotUIMessage,
  BotUI,
  BotUIAction,
  BotUIMessageList,
} from "@botui/react"

import { createBot, BOTUI_BLOCK_TYPES } from "botui"

import Button from "design_system/core/components/button"
import exampleNpcAvatar from "./scripts/1_intro/turtle-npc.png"
import duolingoAvatar from "../../../../../app/assets/images/profile_icons/duolingo.png"
import { IconFlag, IconFlagFilled, IconHome } from "@tabler/icons-react"
import "./index.css"
import { playScript } from "./scripts/1_intro/example_script"
import { renderer as actionRenderer } from "./action_renderer"
import { renderer as messageRenderer } from "./message_renderer"

let timer: NodeJS.Timeout

const Header = () => {
  const useStoryTellerStore = (selector) =>
    useStore(jsStoryTellerStore, selector)
  const progress = useStoryTellerStore((state) => state.progress)
  const total = useStoryTellerStore((state) => state.total)
  const isComplete = progress >= total

  return (
    <div
      className="border-b  p-4 sticky top-0 z-20 bg-white border-secondary-200 dark:bg-dark dark:border-secondary-700"
      style={{ width: "--webkit-fill-available" }}
    >
      <div>
        <p className="uppercase text-secondary-500 text-xs dark:text-secondary-400">
          Story:
        </p>
        <h1 className="font-fancy post-title text-3xl">Céad míle fáilte</h1>
      </div>
      <div className="flex-1 flex items-center gap-4 mt-3">
        <div>
          <a href="#home">
            <IconHome
              className="text-secondary-600 relative z-10 dark:text-secondary-400"
              size={18}
            />
          </a>
        </div>
        <div className="flex-1 bg-secondary-200 rounded-full h-1.5 dark:bg-secondary-600">
          <div
            className="relative transition-width duration-500 ease-in-out"
            style={{ width: `${((progress || 0.1) / total) * 100}%` }}
          >
            <div
              className="absolute right-0 bg-earth border-4  -translate-y-1/2 translate-x-1/2 top-1/2 border-white dark:border-dark"
              style={{ minWidth: "max-content" }}
            >
              <img src={duolingoAvatar} className="pixel-art" width={24} />
            </div>
            <div className="bg-dark h-1.5 rounded-full dark:bg-white"></div>
          </div>
        </div>
        <div>
          {isComplete ? (
            <IconFlagFilled
              className="relative z-10 dark:text-secondary-400 text-warning"
              size={18}
            />
          ) : (
            <IconFlag
              className="relative z-10 dark:text-secondary-400 text-secondary-300"
              size={18}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const StoryTeller = ({
  darkMode = false,
  className,
  children,
  onProgress,
  story = {},
}) => {
  const useStoryTellerStore = (selector) =>
    useStore(jsStoryTellerStore, selector)
  const initialize = useStoryTellerStore((state) => state.initialize)
  const botRef = useRef(createBot())
  const mybot = botRef.current

  mybot.use((block) => {
    console.log({ block })

    if (block.type == BOTUI_BLOCK_TYPES.MESSAGE) {
      block.data.text = block.data?.text?.replace(
        /!\(([^\)]+)\)/gim,
        "<i>$1</i>"
      )
    }
    return block
  })

  useEffect(() => {
    initialize({
      total: 10,
    })
  }, [])

  useEffect(() => {
    clearTimeout(timer) // makes sure to clear previous call
    timer = setTimeout(() => playScript(mybot, { onProgress }), 50)
  }, [mybot, onProgress])

  return (
    <div className="container min-h-screen flex items-stretch py-16">
      <div className="grid grid-cols-12 gap-6 flex-auto">
        <aside className="col-span-3 pt-32">
          <img
            src={exampleNpcAvatar}
            className="pixel-art w-full sticky top-24"
          />
        </aside>
        <div className="col-span-9 md:col-span-7">
          <div className="storyPages">
            <div className="storyPagesContainer">
              <hr />
              <hr />
              <hr />
            </div>
          </div>
          <div className="flex flex-col h-full will-change-transform border border-secondary-200 dark:border-secondary-700">
            <Header />
            <BotUI bot={mybot}>
              <BotUIMessageList renderer={messageRenderer} />
              <BotUIAction renderer={actionRenderer} />
            </BotUI>
          </div>
        </div>
        <div className="col-span-2 pt-44 hidden md:block">
          <div className="sticky top-32 text-xs">
            <p>
              Unlike other demos,{" "}
              <span className="inline-block">ours is free ❤️</span>️
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryTeller

import React from "react"
import PropTypes from "prop-types"
import { useSprite } from "./index"
import classNames from "classnames"
export { useSprite, loadImage } from "./index"

const noop = () => {}

const propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sprite: PropTypes.string,
  scale: PropTypes.number,
  direction: PropTypes.string,
  shouldAnimate: PropTypes.bool,
  loop: PropTypes.bool,
  startFrame: PropTypes.number,
  fps: PropTypes.number,
  stopLastFrame: PropTypes.bool,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  onEnd: PropTypes.func,
  frameCount: PropTypes.number,
  wrapAfter: PropTypes.number,
  frame: PropTypes.number,
  reset: PropTypes.bool,
}

const SpriteAnimator = (
  {
    startFrame,
    sprite,
    width,
    height,
    direction,
    onError,
    onLoad,
    onEnd,
    frameCount,
    fps,
    offset,
    shouldAnimate,
    stopLastFrame,
    reset,
    frame,
    className,
    scale,
    wrapAfter,
  },
  _context
) => {
  const style = useSprite({
    startFrame,
    sprite,
    width,
    height,
    direction,
    onError,
    onLoad,
    onEnd,
    frameCount,
    fps,
    offset,
    shouldAnimate,
    stopLastFrame,
    reset,
    frame,
    scale,
    wrapAfter,
  })

  return <div className={classNames("pixel-art", className)} style={style} />
}

SpriteAnimator.propTypes = propTypes
SpriteAnimator.defaultProps = {
  scale: 1,
  direction: "horizontal",
  shouldAnimate: true,
  loop: true,
  startFrame: 0,
  fps: 60,
  onError: noop,
  onLoad: noop,
  onEnd: noop,
}

export default SpriteAnimator

import FireHammer from "./fire_hammer"

import WaterBlast from "./water_blast"
import WaterDragon from "./water_dragon"
import WaterSpike from "./water_spike"

const Spells = {
  FireHammer,
  WaterDragon,
  WaterBlast,
  WaterSpike,
}

export default Spells

import React from "react"
import Button from "design_system/core/components/button"
import AdventureStoryPreview from "@love-irish/adventure-story-preview"
import { LootDropBlock } from "@love-irish/adventure-story-teller"
import { faker } from "@faker-js/faker"
import duolingoAvatar from "images/profile_icons/duolingo.png"

import * as classNames from "classnames"

const CodexSection = () => (
  <div className="container grid grid-cols-2 gap-10 py-24">
    <div className="mt-16">
      <div className="sticky top-10">
        <h2 className="text-7xl font-mono-header">
          Discover loot &amp; rewards
        </h2>
        <p className="text-lg">
          There are many ways to earn loot drops and rewards. Completing stories
          and doing dungeon runs is the best way to find season loot and
          rewards. Some are completely random when going around the site.
        </p>
        <p className="mt-10">
          <a href="" className="font-bold underline">
            Learn more &rarr;
          </a>
        </p>
      </div>
    </div>
    <div className="">
      <div className="grid grid-cols-2 gap-4 justify-start items-start -mt-10 ">
        <div className="grid gap-4 mt-16">
          <LootDropBlock
            item={{
              name: "Codex",
              summary:
                "Your dictionary to keep track of unlocked words and phrases",
            }}
            className="!max-w-full !border !border-warning"
          />
          <LootDropBlock
            item={{
              name: "Trading cards",
              summary:
                "The most special of the items, TCG have unique serial numbers for each one discovered and can unlock special features on the site like dark mode",
            }}
            className="!max-w-full !border !border-warning"
          />
        </div>
        <div className="grid gap-4 -mt-6">
          <LootDropBlock
            item={{
              // name: "Íocón na ulchabhán glas",
              name: "Avatars",
              summary: "Choose your avatar to represent you in the game",
              icon: duolingoAvatar,
            }}
            className="!max-w-full !border !border-warning"
          />
          <LootDropBlock
            item={{ name: "Theme songs", summary: "Choose your theme song" }}
            className="!max-w-full !border !border-warning"
          />
          <LootDropBlock
            item={{
              name: "Drop cap set",
              summary:
                "A set of drop cap letter you can use in your profile and content",
            }}
            className="!max-w-full !border !border-warning"
          />
        </div>
      </div>
    </div>
  </div>
)

export default CodexSection

import React from "react"
import CelticDropCap from "design_system/application/components/celtic_drop_cap"
import { useRemarkSync } from "react-remark"
import wrapAmpersand from "design_system/utils/ampersand_wrapper"
import rehypeRaw from "rehype-raw"
import classNames from "classnames"
import RemarkDropcap from "design_system/utils/remark_dropcap"
import rehypeExternalLinks from "rehype-external-links"

const Content = ({ content = "", dropcap = false, className, ...props }) => {
  const markdownContent = content ? content : ""
  const reactContent = useRemarkSync(wrapAmpersand(markdownContent), {
    remarkPlugins: [RemarkDropcap],
    remarkToRehypeOptions: { allowDangerousHtml: true },
    rehypePlugins: [
      rehypeRaw,
      [rehypeExternalLinks, { rel: ["noopener"], target: "_blank" }],
    ],
    rehypeReactOptions: {
      components: {
        span: (props) => {
          if (props.className === "dropcap" && dropcap) {
            return (
              <CelticDropCap
                className="m-0 mr-3"
                size={typeof dropcap === "string" ? dropcap : "md"}
                letter={props.children[0].toLowerCase()}
              />
            )
          }

          return <span {...props} />
        },
      },
    },
  })

  return (
    <div
      className={classNames(
        "prose prose-ol:text-sm prose-ul:text-sm prose-hr:my-4 prose-blockquote:-ml-5 first-line:italic max-w-none prose-headings:font-normal prose-img:!mt-0",
        className
      )}
      {...props}
    >
      {reactContent}
    </div>
  )
}

Content.propTypes = {}

export default Content

import sprite from "./sprite.png"
import icon32 from "./icon_32.png"
import elements from "design_system/adventure/codex/elements"

const Spell = {
  type: "spell",
  name: "Water spike",
  irishName: "Blast uisce",
  element: elements.water.id,
  spellText: {
    english: "Come forth, water dragon!",
    irish: "Tar aniar, dragan uisce!",
  },
  sprite,
  spriteSettings: {
    frameCount: 13,
    height: 192 / 2,
    width: 256 / 2,
  },
  icons: {
    32: icon32,
  },
}

export default Spell

import React from "react"
import classNames from "classnames"
import { IconPhoto } from "@tabler/icons-react"

const LofiImage = ({
  height = "aspect-square",
  background = "bg-secondary-100 dark:bg-secondary-900",
  iconColor = "text-secondary-300 dark:text-secondary-600",
  showIcon = true,
  icon: Icon = IconPhoto,
  iconProps = {},
  className = "",
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center text-lg flex-col gap-1 text-secondary-400 dark:text-secondary-600",
        background,
        height,
        iconColor,
        className
      )}
    >
      {showIcon && <Icon {...iconProps} />}
      {children}
    </div>
  )
}

export default LofiImage

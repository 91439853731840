import React, { useEffect } from "react"
import Title from "design_system/core/components/title"
import { IconBook, IconSearch, IconVolume } from "@tabler/icons-react"
import classNames from "classnames"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import useSound from "use-sound"
import "./index.css"
import Excerpt from "design_system/core/components/excerpt"

const CodexRecord = ({
  className = "",
  children = null,
  level = 1,
  item,
  ...props
}) => {
  console.log({ item })
  const [play] = useSound(item.audioUrl || "")

  const handlePlay = (e) => {
    e.stopPropagation()
    play()
  }
  return (
    <div className={className}>
      <div className="px-4 pt-4 text-2xs text-secondary-600 flex gap-1 justify-between items-center">
        <div className="flex gap-1 items-center">
          <IconBook
            className="text-secondary-400 inline-block align-middle"
            size={14}
          />
          <Tooltip title="Dictionary words or phrases that can gain experience as you encounter them">
            Codex record
          </Tooltip>
        </div>
        <span className="font-bold text-warning flex gap-2 items-center">
          <div className="bg-warning-50 rounded overflow-hidden h-1.5 w-8 flex border border-warning">
            <div className="bg-warning" style={{ width: "50%" }}>
              &nbsp;
            </div>
          </div>
          LVL {level}
        </span>
      </div>
      <p
        className="post-title text-2xl text-definition px-4 pb-6 pt-3"
        {...props}
      >
        <span onClick={handlePlay}>&ldquo;{item.name}&rdquo; </span>
        {item.audioUrl && (
          <button
            className="!bg-none cursor-pointer"
            onClick={handlePlay}
            type="button"
          >
            <IconVolume
              size={24}
              className="inline-block text-secondary-300"
              onClick={handlePlay}
            />
          </button>
        )}
      </p>

      {item.summary && (
        <div className="px-4 pb-4">
          <p className="text-xs text-secondary uppercase mb-2">Translation:</p>
          <Content
            content={item.summary}
            className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
          />
        </div>
      )}

      <div className="p-3 text-xs  rounded-b border-t border-secondary-200 flex gap-2 bg-secondary-50 text-secondary-600 dark:bg-dark-900 dark:text-secondary-100 dark:border-dark-500">
        <IconSearch
          className="text-secondary-400 inline-block align-middle"
          size={14}
        />
        <div>
          {level === 1 ? "Unlocked a new codex record!" : "Found again!"}{" "}
          <span className="text-warning font-bold dark:text-warning-400">
            + 5 EXP
          </span>
        </div>
      </div>
    </div>
  )
}

export default CodexRecord

import React from "react"
import "./index.css"
import { IconHelp } from "@tabler/icons-react"
import Tooltip from "design_system/core/components/tooltip"
import { Profile } from "design_system/utils/types/profile"
import Excerpt from "design_system/core/components/excerpt"
import classNames from "classnames"
import ProfileSocialLink from "design_system/community/components/profile_social_link"
export interface ProfileCardProps {
  profile: Profile
}
const ProfileCard = ({ profile }: ProfileCardProps) => {
  const i18nDate = new Date(profile.createdAt)
  const formattedDate = i18nDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })
  return (
    <div className="card profile-card">
      <div className="profile-card-body">
        <div className="flex gap-6 p-5">
          <div className="flex-shrink-0">
            {profile.iconUrl ? (
              <img className=" pixel-art" src={profile.iconUrl} width={64} />
            ) : (
              <div className="profile-card-avatar-placeholder"></div>
            )}
          </div>
          <div className="flex-grow min-w-0">
            <h1 className="font-fancy text-xl sm:text-2xl break-words">
              @{profile.slug}
            </h1>
            {profile.shortBio && (
              <Excerpt className="text-xs mt-1 italic break-words" lines="3">
                {profile.shortBio}
              </Excerpt>
            )}
          </div>
        </div>
        <div className="profile-card-content">
          <div className="divide-y gap-3 grid">
            {!profile.slug && <div></div>}
            {profile.slug && (
              <div className="grid gap-2 divide-x text-center grid-flow-col mt-3 mb-4">
                <div className="grid gap-1">
                  <div className="text-xs">Followers</div>
                  <h3 className="text-xl font-mono">
                    {profile.profileFollowsCount || 0}
                  </h3>
                </div>
                <div className="grid gap-1 pl-2">
                  <div className="text-xs">Following</div>
                  <h3 className="text-xl font-mono">
                    {profile.followingProfileCount || 0}
                  </h3>
                </div>
                <div className="grid gap-1 pl-2">
                  <div className="text-xs">Level</div>
                  <h3 className="text-xl font-mono">1</h3>
                </div>
                <div className="grid gap-1 pl-2">
                  <div className="text-xs">
                    Element{" "}
                    <Tooltip title="Special loot drops and abilities depending on your element">
                      <IconHelp
                        className="inline-block text-secondary-400 align-sub"
                        size={12}
                      />
                    </Tooltip>
                  </div>
                  <h3
                    className={classNames(
                      "text-xl font-mono font-bold",
                      profile.element?.labelCssName
                    )}
                  >
                    {profile.element?.name || "None"}
                  </h3>
                </div>
              </div>
            )}
            <div className="px-3 pt-3">
              <ul className="grid gap-2">
                <li>Joined {formattedDate}</li>
                {profile.correctionsWelcome && (
                  <li>
                    Corrections welcome{" "}
                    <Tooltip title="People can suggest corrections on your content">
                      <IconHelp
                        className="inline-block text-secondary-400 align-middle"
                        size={16}
                      />
                    </Tooltip>
                  </li>
                )}
              </ul>
            </div>
            {[profile.facebookUsername, profile.duolingoUsername].some(
              (value) => value
            ) && (
              <div className="px-3 pt-4 pb-1">
                <ul className="grid gap-2">
                  {profile.duolingoUsername && (
                    <li>
                      <ProfileSocialLink
                        profile={profile}
                        type="duolingo"
                        label="username"
                      />
                    </li>
                  )}

                  {profile.facebookUsername && (
                    <li>
                      <ProfileSocialLink
                        profile={profile}
                        type="facebook"
                        label="username"
                      />
                    </li>
                  )}
                </ul>
              </div>
            )}
            <div className="py-3">
              <div>
                <a
                  href="/settings/profile"
                  className="rounded bg-dark-50 px-2.5 py-1.5 text-sm font-semibold text-dark-600 shadow-sm hover:bg-dark-100 block text-center"
                >
                  Edit profile
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard

import React from "react"
import Tooltip from "design_system/core/components/tooltip"
import { monthFromInt, weekdayFromInt } from "design_system/libs/i18n/ga"

const DateHeader = ({ currentDate = new Date(), as: Component = "p" }) => {
  const weekdayTranslations = weekdayFromInt(currentDate.getDay())

  return (
    <Component className="text-base">
      <Tooltip
        title={`${weekdayTranslations.english}, ${currentDate.getDate()} ${
          monthFromInt(currentDate.getMonth()).english
        }`}
      >
        <span className="text-definition !font-normal">
          {weekdayTranslations.irish}, {currentDate.getDate()}{" "}
          {monthFromInt(currentDate.getMonth()).irish}
        </span>
      </Tooltip>
    </Component>
  )
}

export default DateHeader

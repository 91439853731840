import { generateFullItem } from "../../../../../../../app/design_system/utils/sample_data/item"
import { jsStoryTellerStore } from "../../../../stores/story_teller"
import isMiseTadgh from "./is-mise-tadhg-na-bogach.mp3"
import diaDuit from "./dia-duit.mp3"
import goHiontach from "./go-hiontach.mp3"
import deasBuladhLeat from "./deas-bualadh-leat.mp3"
import codexIcon from "./codex-2.png"
import spellData from "design_system/adventure/codex/spells"

const goHiontachItem = generateFullItem({
  name: "Go hIontach!",
  summary: "Great!",
  slug: "go-hiontach",
  audioUrl: goHiontach,
})

export const playScript = (bot, actions = {}) => {
  const incrementProgress = jsStoryTellerStore.getState().incrementProgress
  const onComplete = jsStoryTellerStore.getState().onComplete

  const codexRecord = generateFullItem({
    name: "Dia duit!",
    slug: "dia-duit",
    summary: "Hello!",
    audioUrl: diaDuit,
  })

  let user = {
    username: null,
  }

  bot
    .wait({ waitTime: 1000 })
    .then(async (res) => {
      return await displayCodexRecord(bot, codexRecord).then(() =>
        incrementProgress()
      )
    })
    .then(async (res) => await bot.wait({ waitTime: 2000 }))
    .then(async (res) => {
      return await displayCodexRecord(
        bot,
        generateFullItem({
          name: "Is mise Tadhg na Bogach",
          slug: "is-mise-tadhg-na-bogach",
          summary: "My name is **Tadhg of the Marsh**",
          audioUrl: isMiseTadgh,
        })
      ).then(() => incrementProgress())
    })
    .then(async (res) => await bot.wait({ waitTime: 4000 }))
    .then(async (res) => {
      return await bot.message
        .add({
          text: "_**Is seanchaí mé**_, or an Irish storyteller and manage the library here. We are researchers and explorers of the Irish language and culture. Many stories were lost but maybe you can help us find them...",
        })
        .then(() => incrementProgress())
    })
    .then(async (res) => await bot.wait({ waitTime: 3000 }))
    .then(async (res) => {
      await askForGoals(bot).then(() => incrementProgress())
    })
    .then(async (res) => await bot.wait({ waitTime: 3000 }))
    .then(async (res) => {
      return await demoSpellCast(bot, user)
    })
    .then(async (res) => await bot.wait({ waitTime: 2000 }))
    .then(async (res) => {
      await askForUsername(bot, user).then(() => incrementProgress())
    })
    .then(async (res) => await bot.wait({ waitTime: 3000 }))
    .then(async (res) => {
      return await bot.message
        .add({
          text: "There will be many challenges you must overcome to become a seanchaí yourself, but I believe in you. \n\n**Let's try one now...**",
        })
        .then(() => incrementProgress())
    })
    .then(async (res) => await bot.wait({ waitTime: 3000 }))
    .then(async (res) => {
      await handleMiniGame(bot, user)
    })
    .then(async (res) => await bot.wait({ waitTime: 3000 }))
    .then(async (res) => {
      return await bot.message
        .add({
          text: "There are many magical creatures around the site who can help you on your journey. Most are friendly and will help you practice your Irish, but _beware of those who bear the mark of the Morrigan..._ ",
        })
        .then(async (res) => await bot.wait({ waitTime: 4000 }))
        .then(
          async () =>
            await bot.message.add({
              text: "...or was it the mark of the Banshee? 😅",
            })
        )
        .then(async (res) => await bot.wait({ waitTime: 3000 }))
        .then(
          async () =>
            await bot.message.add({
              text: "You are ready to begin your adventure!",
            })
        )
        .then(async (res) => await bot.wait({ waitTime: 2000 }))
        .then(
          async () =>
            await bot.message.add({
              text: "Oh and one more thing...",
            })
        )
        .then(async (res) => {
          return await bot.wait({ waitTime: 5000 })
        })
        .then(
          async () =>
            await displayLootDrop(
              bot,
              generateFullItem({
                name: "Codex",
                summary:
                  "A magical book that records experience with words and phrases. Can be found at [love.irish/codex](/codex).",
                icon: codexIcon,
              })
            ).then(() => incrementProgress())
        )
        .then(async () => await bot.wait({ waitTime: 1000 }))
        .then(
          async () =>
            await bot.message.add({
              text: "**This is your very own [Codex](/codex)!** It will help you keep track of your progress as you practice words and phrases on the site. You can access it at any time by clicking on the Codex icon in the top right corner.",
            })
        )
    })
    .then(async (res) => await bot.wait({ waitTime: 5000 }))
    .then(async (res) => {
      return await bot.message
        .add({
          text: "**[ Story complete ]**",
        })
        .then(async (res) => await bot.wait({ waitTime: 5000 }))
        .then(
          async () =>
            await bot.message.add({
              text: "**Thank you for trying our demo! ❤️** \n\n We hope you enjoyed it. \n\n If you'd like to learn more about the Irish language, check out our other resources.",
            })
        )
    })
    .then(async (res) => await bot.wait({ waitTime: 2000 }))
    .then((res) => displayGameCredit(bot))
    .then((res) => onComplete())
}

const displayCodexRecord = async (bot, item) => {
  await bot.message.getAll().then(async (messages) => {
    const existingCodexRecords = messages.filter(
      (message) => message.data?.item?.id === item?.id
    )

    await bot.message.add(
      {
        item,
        level: existingCodexRecords.length + 1,
      },
      { bringIntoView: true, messageType: "codexRecord" }
    )
  })
}

const displayLootDrop = async (bot, item) => {
  await bot.message.add(
    {
      item,
    },
    { bringIntoView: true, messageType: "lootDrop" }
  )
}

const displayGameCredit = async (bot) => {
  await bot.message.add({}, { bringIntoView: true, messageType: "gameCredit" })
}

export const askForGoals = async (bot, user) => {
  return await bot
    .wait({ waitTime: 1000 })
    .then(async (res) => {
      await bot.message.add(
        {
          src: "https://miro.medium.com/v2/resize:fit:1280/1*Nv4oqbfv5IwRSMdLGtFZuA.gif",
          className: "w-full max-[350px]",
        },
        { messageType: "image" }
      )
    })
    .then(async (res) => {
      await bot.message.add({
        text: "What brings you here?",
      })
    })
    .then(async (res) => {
      return await bot.action.set(
        {
          options: [
            { label: "I'm here to help", value: "help" },
            { label: "I'm here to learn", value: "learn", selected: true },
          ],
        },
        { actionType: "select", confirmButtonText: "Continue" }
      )
    })
    .then(async (data) => {
      await bot.message.add({
        text: `You selected ${data.selected.label}`,
      })
    })
}

export const demoSpellCast = async (bot, user) => {
  const spells = Object.entries(spellData)

  return await bot
    .wait({ waitTime: 1000 })
    .then(async (res) => {
      await bot.message.add({
        text: "Let's try casting a spell! You'll only have a couple when you're just starting out, but you can find more spells as you complete stories and through loot drops.",
      })
    })
    .then(async (res) => {
      const options = spells.map(([name, data], index) => ({
        label: data.name,
        value: name,
        selected: index === 0,
      }))
      return await bot.action.set(
        {
          options,
        },
        {
          actionType: "select",
          confirmButtonText: "Continue",
          messageType: "miniGameResponse",
        }
      )
    })
    .then(async (data) => {
      await bot.message.add(
        {
          item: {
            ...(spellData[data.selected.value] || {}),
          },
        },
        { fromHuman: true, messageType: "spellCast" }
      )
    })
    .then(async (res) => await bot.wait({ waitTime: 5000 }))
    .then(async (res) => await displayCodexRecord(bot, goHiontachItem))
}

export const handleMiniGame = async (bot, user) => {
  return await bot.action
    .set(
      {
        // isMultiSelect: true,
        total: 10,
        options: [
          { label: "Is", value: "yes" },
          { label: "mise", value: "no" },
          { label: `@${user.username}`, value: "no" },
        ],
      },
      { actionType: "miniGame" }
    )
    .then(async (res) => {
      return await displayCodexRecord(
        bot,
        generateFullItem({
          name: "Deas bualadh leat",
          summary: "Nice to meet you",
          slug: "deas-bualadh-leat",
          audioUrl: deasBuladhLeat,
        })
      )
    })
}

export const askForUsername = async (bot, user) => {
  return await bot
    .wait({ waitTime: 1000 })
    .then(async (res) => {
      await bot.message.add({
        text: "What would you like as your username?",
      })
    })
    .then(async (res) => {
      return await bot.action.set(
        {
          placeholder: "enter your username",
          required: true,
          type: "text",
          name: "username",
        },
        { actionType: "input" }
      )
    })
    .then(async (data) => {
      user.username = data.text
      await displayCodexRecord(bot, goHiontachItem)
      return data
    })
    .then(async (data) => {
      await bot.message.add({
        text: `**@${user.username}** will be your username for the site and an easy way for others to find you. You can change it at any time in your profile settings and see your profile at [love.irish/@${user.username}](/${user.username}) where you can feature loot and achievements earned on your adventure.`,
      })
    })
}

import React from "react"
import * as classNames from "classnames"

interface AdventureStoryPreviewProps {
  title?: string
  className?: string
}

export const AdventureStoryPreview = ({
  title,
  className,
}: AdventureStoryPreviewProps) => (
  <div>
    <div className="storyPages">
      <div className="storyPagesContainer">
        <hr />
        <hr />
        <hr />
      </div>
    </div>
    <div
      className={classNames(
        "flex flex-col h-full will-change-transform border border-secondary-200 dark:border-secondary-700 min-h-[225px] p-4 font-fancy text-xl rounded-bl-[5px] bg-secondary-50/80",
        className
      )}
    >
      <div>{title}</div>
    </div>
  </div>
)

export default AdventureStoryPreview

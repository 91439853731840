import React from "react"

import logo from "images/brand/logos/wordmark.svg"
import { FiMapPin } from "react-icons/fi"
import Tooltip from "design_system/core/components/tooltip"
import ContentCloser from "design_system/application/components/content_closer"
import routes from "design_system/utils/routes"
import analytics from "design_system/utils/analytics"

const productLinks = [
  {
    name: "Interviews",
    href: routes.interviewsPath(),
    translated: "Agallaimh",
  },
  // { name: "Blog", href: routes.blogPostsPath(), translated: "Blag" },
  // { name: "Shop", href: routes.shopPath(), translated: "Siopa" },
]

const libraryLinks = []

const supportLinks = [
  // { name: "Community guidelines", href: routes.sitemapPath(), translated: "Léarscáil" },
  { name: "FAQ", href: routes.faqPath(), translated: "Ceisteanna Coitianta" },
  // { name: "Changelog", href: routes.sitemapPath(), translated: "Léarscáil" },
  // { name: "Sitemap", href: routes.sitemapPath(), translated: "Léarscáil" },
  // { name: "Privacy", href: routes.sitemapPath(), translated: "Léarscáil" },
  // { name: "Terms", href: routes.sitemapPath(), translated: "Léarscáil" },
]

const companyLinks = [
  { name: "About us", href: routes.aboutUsPath(), translated: "Fúinn" },
  // { name: "Contact", href: routes.aboutUsPath(), translated: "Fúinn" },
  // { name: "Artists", href: routes.aboutUsPath(), translated: "Fúinn" },
]

const AppFooter = () => {
  // eslint-disable-next-line camelcase
  const handleScrollToTopClick = () =>
    analytics.track("clicked#scroll_to_top", { link_location: ["footer"] })
  const handleLinkClick = ({ label, href, section }) =>
    analytics.trackLinkClick({ label, href, location: ["footer", section] })

  return (
    <footer className="grow-0 mt-12">
      <div className="container">
        <ContentCloser scrollToTop onScrollClick={handleScrollToTopClick} />

        <div className="grid grid-cols-2 gap-6 md:grid-cols-4 pb-10">
          <div className="md:col-span-1 col-span-2 text-sm">
            <img width={75} src={logo} alt="" />

            <p className="mt-7 mb-4 text-gray-800">
              <FiMapPin className="inline" /> Based in the{" "}
              <strong>San Francisco Bay Area</strong>
            </p>

            <p>
              <Tooltip
                title="Broken Irish is better than clever English️"
                wrapperClass="inline"
              >
                <em className="text-definition">
                  Is fearr Gaeilge bhriste ná Béarla cliste
                </em>
              </Tooltip>{" "}
              &middot; The best way to get better at something is to practice so
              don&rsquo;t worry about being perfect!
            </p>

            <p className="mt-4">
              This site is run by and for fans and learners — corrections welcome ❤️
            </p>
          </div>
          <div>
            <h3 className="text-lg font-fancy">Products</h3>
            <p className="text-sm mb-3 text-gray-500">
              <em>Things we made</em>
            </p>
            <ul>
              {productLinks.map(({ name, href, translated }) => (
                <li key={name}>
                  <a
                    href={href}
                    onClick={() =>
                      handleLinkClick({
                        label: name,
                        section: "products",
                        href,
                      })
                    }
                  >
                    {name}{" "}
                    <em className="text-gray-400 text-xs">{translated}</em>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-fancy">Library</h3>
            <p className="text-sm mb-3 text-gray-500">
              <em>Things we found</em>
            </p>
            <ul>
              {libraryLinks.map(({ name, href, translated }) => (
                <li key={name}>
                  <a
                    href={href}
                    onClick={() =>
                      handleLinkClick({ label: name, section: "library", href })
                    }
                  >
                    {name}{" "}
                    <em className="text-gray-400 text-xs">{translated}</em>
                  </a>
                </li>
              ))}
              <em className="text-xs">coming soon!</em>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-fancy mb-2">Help &amp; support</h3>
            <ul>
              {supportLinks.map(({ name, href, translated }) => (
                <li key={name}>
                  <a
                    href={href}
                    onClick={() =>
                      handleLinkClick({ label: name, section: "support", href })
                    }
                  >
                    {name}{" "}
                    <em className="text-gray-400 text-xs">{translated}</em>
                  </a>
                </li>
              ))}
            </ul>

            <h3 className="text-lg font-fancy mt-6 mb-2">Company</h3>
            <ul>
              {companyLinks.map(({ name, href, translated }) => (
                <li key={name}>
                  <a
                    href={href}
                    onClick={() =>
                      handleLinkClick({ label: name, section: "company", href })
                    }
                  >
                    {name}{" "}
                    <em className="text-gray-400 text-xs">{translated}</em>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <hr className="mt-7 mb-3" />

        <div className="flex justify-end my-5 text-sm">
          <small>
            <em>
              <Tooltip title="I am a footer 🦶" wrapperClass="text-definition !font-normal">
                Is footer mé
              </Tooltip>
            </em>
          </small>
        </div>
      </div>
    </footer>
  )
}



export default AppFooter
